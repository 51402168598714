import { Pipe, PipeTransform } from '@angular/core';
import { Organization, User } from '@razroo-zeta/data-models';

@Pipe({
    name: 'orgInitials',
    standalone: false
})
export class OrgInitialsPipe implements PipeTransform {

  transform(organization: Organization, userProfile: User): string {
    if(organization && userProfile && organization.orgId === userProfile.userId) {
      return userProfile.firstName.split('')[0].toUpperCase() + userProfile.lastName.split('')[0].toUpperCase()
    } else {
      return organization && organization.orgId && organization.orgId.split('')[0].toUpperCase();
    }
  }

}
