import { getTicketWorkspaceBoardSuccess, getTicketWorkspaceBoard, loadTicketWorkspaceSprintBoardsSuccess, loadTicketWorkspaceSprintBoards } from '../../../../ticket-workspaces/src/lib/+state/ticket-workspaces.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as TicketsActions from './tickets.actions';
import { ColumnsEntity, EpicSuggestionsEntity, TicketsEntity } from './tickets.models';
import { DatasetCollection, Ticket, TicketWorkspace } from '@razroo-zeta/data-models';

export const TICKETS_FEATURE_KEY = 'tickets';

export interface TicketsState extends EntityState<TicketsEntity> {
  selectedId?: string | number; // which Tickets record has been selected
  loaded: boolean; // has the Tickets list been loaded
  selectedEpicId?: number;
  error?: string | null; // last known error (if any)
  generatingAI?: boolean;
  generatingTicketAI?: boolean;
  generatingTicketsAI?: boolean;
}
export interface TicketHistoryState extends EntityState<TicketsEntity> {
  selectedId?: string | number; // which Tickets record has been selected
  loaded: boolean; // has the Tickets list been loaded
  edited: boolean;
  error?: string | null; // last known error (if any)
}

export interface TitleTicketsState extends EntityState<TicketsEntity> {
  loaded: boolean;
  generating: boolean;
  error?: string | null; // last known error (if any)
}

export interface EpicSuggestionsState extends EntityState<EpicSuggestionsEntity> {
  loaded: boolean;
  generating: boolean;
  error?: string | null; // last known error (if any)
}

export interface EpicsState extends EntityState<TicketsEntity> {
  loaded: boolean;
  generating: boolean;
  error?: string | null; // last known error (if any)
}

export interface State {
  tickets: TicketsState;
  ticketHistory: TicketHistoryState;
  titleTickets: TitleTicketsState;
  epicSuggestions: EpicSuggestionsState;
  epics: EpicsState;
  columns: any[];
  communityTicketWorkspaces?: TicketWorkspace[];
  datasetsTotalHits?: number;
  datasetCollections?: DatasetCollection[];
  datasetCollectionsLoaded?: boolean;
  datasetCollection?: DatasetCollection;
  datasetCollectionsTotalHits?: number;
  ticketUpdating?: boolean;
  columnsLoaded?: boolean;
  epicGenerating?: boolean;
  generatingTitleTickets?: boolean;
  ticketUpdatingStatus: {
    description?: boolean;
    epic?: boolean;
    estimatedStoryPoint?: boolean;
    labels?: boolean;
  }
  boardId?: number;
  ticketTitles?: Ticket[];
  selectedEpicId?: number;
}
export interface TicketsPartialState {
  readonly [TICKETS_FEATURE_KEY]: TicketsState;
}

export function selectWorkspaceId(ticket: Ticket): string {
  //In this case this would be optional since primary key is id
  return `${ticket?.workspaceId}-${ticket?.ticketNumber}`;
}

export function selectTitleTicketId(ticket: Ticket): number {
  //In this case this would be optional since primary key is id
  return ticket?.ticketNumber;
}

export function selectColumnId(column: ColumnsEntity): string {
  return column.columnId;
}

export function selectEpicSuggestionId(epicSuggestion: EpicSuggestionsEntity): string {
  return epicSuggestion.id;
}

export function selectEpicId(epic: TicketsEntity): string {
  return `${epic.ticketNumber}`;
}

export const ticketsAdapter: EntityAdapter<TicketsEntity> =
  createEntityAdapter<TicketsEntity>({
    selectId: selectWorkspaceId
  });
export const titleTicketsAdapter: EntityAdapter<TicketsEntity> =
  createEntityAdapter<TicketsEntity>({
    selectId: selectTitleTicketId
  });  
export const ticketHistoryAdapter: EntityAdapter<TicketsEntity> =
  createEntityAdapter<TicketsEntity>();

export const epicSuggestionsAdapter: EntityAdapter<EpicSuggestionsEntity> =
  createEntityAdapter<EpicSuggestionsEntity>({
    selectId: selectEpicSuggestionId
  });  
export const epicsAdapter: EntityAdapter<TicketsEntity> =
  createEntityAdapter<TicketsEntity>({
    selectId: selectEpicId
  });  
  
export const ticketsInitialState: TicketsState = ticketsAdapter.getInitialState({
  loaded: false,
  generatingTicketAi: false,
  generatingTicketsAI: false
})

export const ticketHistoryInitialState: TicketHistoryState = ticketHistoryAdapter.getInitialState({
  loaded: false,
  edited: false
});

export const titleTicketsInitialState: TitleTicketsState = titleTicketsAdapter.getInitialState({
  loaded: false,
  generating: false,
  deleting: false
})

export const epicSuggestionsInitialState: EpicSuggestionsState = epicSuggestionsAdapter.getInitialState({
  loaded: false,
  generating: false,
  deleting: false
})

export const epicsInitialState: EpicsState = epicsAdapter.getInitialState({
  loaded: false,
  generating: false,
  deleting: false
})

export const initialState: State = ticketsAdapter.getInitialState(
  {
    // set initial required properties
    tickets: ticketsInitialState,
    ticketHistory: ticketHistoryInitialState,
    titleTickets: titleTicketsInitialState,
    epicSuggestions: epicSuggestionsInitialState,
    epics: epicsInitialState,
    columns: [],
    communityTicketWorkspaces: [],
    datasetCollections: [],
    datasetCollection: undefined,
    loaded: false,
    columnsLoaded: false,
    ticketUpdating: false,
    epicGenerating: false,
    generatingTitleTickets: false,
    ticketUpdatingStatus: {
      description: false,
      epic: false,
      estimatedStoryPoints: false,
      labels: false
    }
  }
);

const reducer = createReducer(
  initialState,
  on(TicketsActions.loadTicket, (state) => ({
    ...state,
    ticketHistory: ticketHistoryAdapter.removeAll({...state.ticketHistory, loaded: false, edited: false})
  })),
  on(TicketsActions.loadTicketSuccess, (state, { ticket }) => ({
    ...state, 
    tickets: ticketsAdapter.upsertMany([ticket], { 
      ...state.tickets, 
      loaded: true,
      selectedId: `${ticket.workspaceId}-${ticket.ticketNumber}`
    }),
    columns: ticket?.columns as any,
  })),
  on(TicketsActions.loadTicketFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(TicketsActions.loadTickets, (state) => ({
    ...state,
    loaded: false,
    error: null,
    ticketHistory: ticketHistoryAdapter.removeAll({...state.ticketHistory, loaded: false})
  })),
  on(TicketsActions.loadTicketsSuccess, (state, { tickets }) =>({
    ...state,
    tickets: ticketsAdapter.setAll(tickets, { ...state.tickets, loaded: true })
  })),
  on(TicketsActions.selectEpic, (state, { epic }) =>({
    ...state,
    selectedEpicId: epic.ticketNumber
  })),
  on(TicketsActions.getAllTitleTicketsForWorkspaceEpicSuccess, (state, {tickets}) => ({
    ...state,
    titleTickets: titleTicketsAdapter.setAll(tickets, {
      ...state.titleTickets,
      loaded: true,
      generating: false
    })
  })),
  on(TicketsActions.getCommunityTicketWorkspacesSuccess, (state, {ticketWorkspaces}) => ({
    ...state,
    communityTicketWorkspaces: ticketWorkspaces
  })),
  on(TicketsActions.createCommunityTicketWorkspaceSuccess, (state, {workspace}) => ({
    ...state,
    communityTicketWorkspaces: [...(state.communityTicketWorkspaces || []), workspace]
  })),
  on(TicketsActions.searchProjectsDatasetCollections, (state) => ({
    ...state,
    datasetCollectionsLoaded: false
  })),
  on(TicketsActions.searchProjectsDatasetCollectionsSuccess, (state, {datasetCollections, totalHits}) => ({
    ...state,
    datasetCollections: datasetCollections,
    datasetCollectionsLoaded: true,
    datasetCollectionsTotalHits: totalHits
  })),
  on(TicketsActions.loadMoreDatasetCollections, (state) => ({
    ...state,
    datasetCollectionsLoaded: false
  })),
  on(TicketsActions.loadMoreDatasetCollectionsSuccess, (state, {datasetCollections, totalHits}) => ({
    ...state,
    datasetCollections: [...(state.datasetCollections || []), ...datasetCollections],
    datasetCollectionsLoaded: true,
    datasetCollectionsTotalHits: totalHits
  })),
  on(TicketsActions.getProjectDatasetCollectionSuccess, (state, {datasetCollection}) => ({
    ...state,
    datasetCollection: datasetCollection
  })),
  on(TicketsActions.updateProjectsDatasetCollection, (state, {collectionId, collectionUpdateParams}) => ({
    ...state,
    datasetCollections: state.datasetCollections?.map(collection => 
      collection.collectionId === collectionId ? {
        ...collection, 
        ...collectionUpdateParams,
        datasets: collectionUpdateParams.datasets
      } : collection
    ),
    datasetCollection: state.datasetCollection ? {
      ...state.datasetCollection, 
      ...collectionUpdateParams,
      datasets: collectionUpdateParams.datasets
    } : undefined
  })),
  on(TicketsActions.createCommunityProjectsCollectionSuccess, (state, {datasetCollection}) => ({
    ...state,
    datasetCollections: [...(state.datasetCollections || []), datasetCollection]
  })),
  on(TicketsActions.searchTicketWorkspacesSuccess, (state, {ticketWorkspaces, totalHits}) => ({
    ...state,
    communityTicketWorkspaces: ticketWorkspaces,
    datasetsTotalHits: totalHits
  })),
  on(TicketsActions.ticketTitleGenerating, (state, {ticket}) => ({
    ...state,
    titleTickets: titleTicketsAdapter.updateOne({id: ticket.ticketNumber, changes: {generating: true}}, state.titleTickets)
  })),
  on(TicketsActions.generateEpicsFromDocumentsUsingAiSuccess, (state, { epicSuggestions }) => ({
    ...state,
    epicSuggestions: epicSuggestionsAdapter.setAll(epicSuggestions, {
      ...state.epicSuggestions,
      loaded: true,
      generating: false
    })
  })),
  on(TicketsActions.suggestNewEpicsSuccess, (state, { epicSuggestions }) => ({
    ...state,
    epicSuggestions: epicSuggestionsAdapter.setAll(epicSuggestions, {
      ...state.epicSuggestions,
      loaded: true,
      generating: false
    })
  })),
  on(TicketsActions.generateProductTicketTitlesAndUpload, (state, {parentTicketNumber}) => ({
    ...state,
    generatingTitleTickets: true,
    epics: epicsAdapter.updateOne({id: parentTicketNumber, changes: {generating: true}}, state.epics),
  })),
  on(TicketsActions.generateProductTicketTitlesAndUploadSuccess, (state, {tickets, parentTicketNumber}) => ({
    ...state,
    titleTickets: titleTicketsAdapter.upsertMany(tickets, {
      ...state.titleTickets,
      loaded: true,
      generating: false
    }),
    epics: epicsAdapter.updateOne({id: parentTicketNumber, changes: {generating: false}}, state.epics),
    selectedEpicId: parentTicketNumber,
    generatingTitleTickets: false
  })),
  on(TicketsActions.deleteTicketTitle, (state, {ticketNumber}) => ({
    ...state,
    titleTickets: titleTicketsAdapter.updateOne({id: ticketNumber, changes: {deleting: true}}, state.titleTickets),
  })),
  on(TicketsActions.deleteTicketTitleSuccess, (state, {ticket}) => ({
    ...state,
    titleTickets: titleTicketsAdapter.removeOne(ticket.ticketNumber, state.titleTickets),
  })),
  on(getTicketWorkspaceBoard, (state) => ({
    ...state,
    tickets: {...state.tickets, loaded: false},
    columns: [...state.columns],
    columnsLoaded: false
  })),
  on(loadTicketWorkspaceSprintBoards, (state) => ({
    ...state,
    tickets: ticketsAdapter.setAll([], {
      ...state.tickets,
      loaded: true
    }),
  })),
  on(loadTicketWorkspaceSprintBoardsSuccess, (state, {tickets}) => ({
    ...state,
    tickets: ticketsAdapter.setAll(tickets, {
      ...state.tickets,
      loaded: true
    }),
  })),
  on(getTicketWorkspaceBoardSuccess, (state, { ticketWorkspaceBoard }) =>({
    ...state,
    tickets: ticketsAdapter.setAll(ticketWorkspaceBoard.tickets ? ticketWorkspaceBoard.tickets : [], { 
      ...state.tickets,
      selectedId: undefined,
      loaded: true
    }),
    columns: ticketWorkspaceBoard.columns ? [...ticketWorkspaceBoard.columns] : [],
    boardId: ticketWorkspaceBoard.boardId,
    columnsLoaded: true
  })),
  on(TicketsActions.moveCardInKanbanBoard, (state, {workspaceId, columnId, ticketNumber, newlyArrangedKanban }) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${workspaceId}-${ticketNumber}`, changes: {columnId}}, state.tickets),
    columns: [...newlyArrangedKanban]
  })),
  
  on(TicketsActions.loadTicketsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(TicketsActions.selectTicket, (state, {ticket}) => ({
    ...state,
    tickets: {
      ...state.tickets,
      selectedId: `${ticket.workspaceId}-${ticket.ticketNumber}`
    }
  })),
  on(TicketsActions.manuallyCreateTicket, (state) => ({
    ...state,
    tickets: {
      ...state.tickets,
      generatingTicketAI: true
    }
  })),
  on(TicketsActions.manuallyCreateTicketFailure, (state) => ({
    ...state,
    tickets: {
      ...state.tickets,
      generatingTicketAI: false
    }
  })),
  on(TicketsActions.manuallyCreateTicketSuccess, (state, {ticket, columns}) => ({
    ...state,
    tickets: ticketsAdapter.addOne(ticket, { 
      ...state.tickets, 
      selectedId: `${ticket.workspaceId}-${ticket.ticketNumber}`, 
      loaded: true, 
      generatingTicketAI: false,
      generating: true
    }),
    columns: (columns && columns.length) ? [
      ...columns as any
    ] : [...state.columns]
  })),
  on(TicketsActions.generateEntireProductTicketUsingAiAndUpload, (state) => ({
    ...state,
    tickets: {
      ...state.tickets,
      generatingTicketAI: true
    }
  })),
  on(TicketsActions.generateEntireProductTicketUsingAiAndUploadFailure, (state) => ({
    ...state,
    tickets: {
      ...state.tickets,
      generatingTicketAI: false
    }
  })),
  on(TicketsActions.generateEntireProductTicketUsingAiAndUploadSuccess, (state, {ticket, columns}) =>({
    ...state,
    tickets: ticketsAdapter.addOne(ticket, { 
      ...state.tickets, 
      selectedId: `${ticket.workspaceId}-${ticket.ticketNumber}`, 
      loaded: true, 
      generatingTicketAI: false
    }),
    columns: (columns && columns.length) ? [
      ...columns as any
    ] : [...state.columns]
  })),
  on(TicketsActions.deleteTicket, (state, {workspaceId, ticketNumber}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${workspaceId}-${ticketNumber}`, changes: {
      deleting: true
    }}, state.tickets),
  })),
  on(TicketsActions.deleteTicketSuccess, (state, {ticket}) => ({
    ...state,
    tickets: ticketsAdapter.removeOne(`${ticket.workspaceId}-${ticket.ticketNumber}`, {...state.tickets, selectedId: undefined}),
    columns: state.columns.map(column => ({
      ...column,
      tickets: column.tickets ? column.tickets.filter(t => t.ticketNumber !== ticket.ticketNumber) : []
    }))
  })),
  on(TicketsActions.generateDescriptionForProductTicketAndUpdate, (state) => ({
    ...state,
    generatingAI: true
  })),
  on(TicketsActions.generateDescriptionForProductTicketAndUpdateSuccess, (state, {ticket}) =>({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: ticket}, {...state.tickets, generatingAI: false})
  })),
  on(TicketsActions.updateTicketWorkspaceTicket, (state, {ticket, ticketUpdateParams}) => ({
    ...state,
    ticketUpdating: true,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: ticketUpdateParams}, state.tickets),
  })),
  on(TicketsActions.updateTicketWorkspaceTicketSuccess, (state, {ticket, ticketUpdateParams}) => ({
    ...state,
    ticketUpdating: false,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {
      condensations: ticketUpdateParams.condensations,
      ...(ticketUpdateParams.parentTicket ? { parentTicket: ticketUpdateParams.parentTicket } : {})
    }}, state.tickets),
  })),
  on(TicketsActions.generateEntireProductTicketUsingAiAndUpdateSuccess, (state, {ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({
      id: `${ticket.workspaceId}-${ticket.ticketNumber}`, 
      changes: {
        ...ticket,
        generating: false
      }
    }, state.tickets),
    columns: state.columns.map(column => ({
      ...column,
      tickets: column.tickets ? column.tickets.map(t =>
        t.ticketNumber === ticket.ticketNumber ? { 
          ...t,
          ...ticket,
          hydrating: false,
          title: ticket.title,
          description: ticket.description,
          labels: ticket.labels,
          epic: ticket.epic,
          estimatedStoryPoints: ticket.estimatedStoryPoints
        } : t
      ) : []
    }))
  })),
  on(TicketsActions.unlinkParentTicketFromTicketSuccess, (state, {ticket}) => ({
    ...state,
    ticketUpdating: false,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {
      epic: ticket.epic,
      parentTicket: ticket.parentTicket
    }}, state.tickets),
  })),
  on(TicketsActions.modifyTicketBasedOnTitleUsingAi, (state) => ({
    ...state,
    ticketUpdating: true,
    ticketUpdatingStatus: {
      ...state.ticketUpdatingStatus,
      description: true,
      epic: true,
      estimatedStoryPoints: true,
      labels: true
    },
  })),
  on(TicketsActions.modifyTicketBasedOnTitleUsingAiSuccess, (state, {ticket}) => ({
    ...state,
    ticketUpdating: false,
    ticketUpdatingStatus: {
      ...state.ticketUpdatingStatus,
      description: false,
      epic: false,
      estimatedStoryPoints: false,
      labels: false
    },
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: ticket}, state.tickets),
  })),
  on(TicketsActions.updateTicketSprintSuccess, (state, {ticket, sprint}) =>({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {boardId: sprint.boardId, sprintBoard: sprint}}, state.tickets)
  })),
  on(TicketsActions.addAiToTicketDescriptionSuccess, (state, {ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {
      description: ticket.description, 
      condensations: ticket.condensations
    }}, state.tickets)
  })),
  on(TicketsActions.addTicketCodeSnippetSuccess, (state, {ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {
      codeSnippet: ticket.codeSnippet
    }}, state.tickets)
  })),
  on(TicketsActions.updateWorkspaceTicketColumn, TicketsActions.updateWorkspaceDataTableTicketColumn, (state, {workspaceId, ticketNumber, ticketUpdateParams}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${workspaceId}-${ticketNumber}`, changes: {
      columnId: ticketUpdateParams.columnId
    }}, state.tickets)
  })),
  on(TicketsActions.uploadTicketToJiraSuccess, (state, {ticket}) => ({
    ...state,
    tickets: ticketsAdapter.removeOne(`${ticket.workspaceId}-${ticket.ticketNumber}`, {...state.tickets, selectedId: undefined})
  })),
  on(TicketsActions.loadTicketHistory, (state) => ({
    ...state,
    ticketHistory: {
      ...state.ticketHistory,
      loaded: false
    }
  })),
  on(TicketsActions.loadTicketHistorySuccess, (state, { ticketHistory }) => {
    const ticketHistoryWithIndexId = ticketHistory.map(historyItem => ({...historyItem, id: ticketHistory.slice().reverse().indexOf(historyItem) + 1}));
    return ({
    ...state,
    ticketHistory: ticketHistoryAdapter.setAll(ticketHistoryWithIndexId, {
      ...state.ticketHistory,
      loaded: true,
      selectedId: ticketHistory.length
    })
  })}),
  on(TicketsActions.loadTicketHistoryItem, (state, {historyItem}) => ({
    ...state,
    tickets: ticketsAdapter.setAll([historyItem], { 
      ...state.tickets, 
      loaded: true,
      selectedId: `${historyItem.workspaceId}-${historyItem.ticketNumber}`
    }),
    ticketHistory: {
      ...state.ticketHistory,
      selectedId: historyItem.id
    }
  })),
  on(TicketsActions.generateUserJourneySuccess, (state, {userJourney, ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {userJourney}}, state.tickets)
  })),
  on(TicketsActions.generateAcceptanceCriteriaForProductTicketSuccess, (state, {acceptanceCriteria, ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {acceptanceCriteria}}, state.tickets)
  })),
  on(TicketsActions.generateBusinessValueForProductTicketSuccess, (state, {businessValue, ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {businessValue}}, state.tickets)
  })),
  on(TicketsActions.generateEstimatedStoryPointsReasonForProductTicketSuccess, (state, {estimatedStoryPointsReason, ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {
      estimatedStoryPointsReason,
      estimatedStoryPoints: ticket.estimatedStoryPoints
    }}, state.tickets)
  })),
  on(TicketsActions.addBackgroundPlusTechnicalNotesPart1Success, (state, {ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {description: ticket.description}}, state.tickets)
  })),
  on(TicketsActions.addBackgroundPlusTechnicalNotesPart2Success, (state, {ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {description: ticket.description}}, state.tickets)
  })),
  on(TicketsActions.addTicketScenarioSuccess, (state, {ticket, newDescription, condensations}) => ({
    ...state, 
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, 
    changes: {
      description: newDescription,
      condensations
    }
    }, state.tickets)
  })),
  on(TicketsActions.removeTicketAttachmentSuccess, (state, {ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {mediaAttachments: ticket.mediaAttachments}}, state.tickets)
  })),
  on(TicketsActions.getAllTicketWorkspaceEpicsSuccess, (state, {epics}) => ({
    ...state,
    epics: epicsAdapter.setAll(epics, {
      ...state.epics,
      loaded: true
    })
  })),
  on(TicketsActions.createEpicProductTicketUsingAi, (state) => ({
    ...state,
    epicGenerating: true,
  })),
  on(TicketsActions.createEpicProductTicketUsingAiSuccess, (state, {epic}) => ({
    ...state,
    epicGenerating: false,
    epics: epicsAdapter.addOne(epic, state.epics)
  })),
  on(TicketsActions.createEpicProductTicketFromEpicSuggestion, (state, {epicSuggestion}) => ({
    ...state,
    epicGenerating: false,
    epicSuggestions: epicSuggestionsAdapter.updateOne({id: epicSuggestion.id, changes: {generating: true}}, state.epicSuggestions)
  })),
  on(TicketsActions.createEpicProductTicketFromEpicSuggestionSuccess, (state, {epicSuggestion,epic}) => ({
    ...state,
    epicGenerating: false,
    epics: epicsAdapter.addOne(epic, state.epics),
    selectedEpicId: epic.ticketNumber,
    epicSuggestions: epicSuggestionsAdapter.removeOne(epicSuggestion.id, state.epicSuggestions)
  })),
  on(TicketsActions.epicFromEpicSuggestionViaDocumentUpload, (state, {epicSuggestion}) => ({
    ...state,
    epicGenerating: false,
    epicSuggestions: epicSuggestionsAdapter.updateOne({id: epicSuggestion.id, changes: {generating: true}}, state.epicSuggestions)
  })),
  on(TicketsActions.epicFromEpicSuggestionViaDocumentUploadSuccess, (state, {epicSuggestion,epic}) => ({
    ...state,
    epicGenerating: false,
    epics: epicsAdapter.addOne(epic, state.epics),
    selectedEpicId: epic.ticketNumber,
    epicSuggestions: epicSuggestionsAdapter.removeOne(epicSuggestion.id, state.epicSuggestions)
  })),
  on(TicketsActions.subscribeToTicketSuccess, TicketsActions.unsubscribeFromTicketSuccess, (state, {ticket}) => ({
    ...state,
    tickets: ticketsAdapter.updateOne({id: `${ticket.workspaceId}-${ticket.ticketNumber}`, changes: {watchers: ticket.watchers}}, state.tickets)
  })),
  on(TicketsActions.renameKanbanColumnSuccess, (state, {columnId, newColumnName}) => {
    const updatedColumns = state.columns.map(column => 
      column.columnId === columnId ? {...column, title: newColumnName} : column
    );
    return ({
      ...state,
      columns: updatedColumns
    })
  }),
  on(TicketsActions.createKanbanColumnSuccess, (state, {columns}) => ({
      ...state,
      columns: [...state.columns, {...columns.columns[columns.columns.length - 1], tickets: []}]
  })),
  on(TicketsActions.deleteKanbanColumnSuccess, (state, {ticketWorkspaceBoard}) => ({
      ...state,
      columns: ticketWorkspaceBoard.columns
  })),
  on(TicketsActions.reorderKanbanColumnsSuccess, (state, {columnsRes}) => ({
    ...state,
    columns: columnsRes.columns.map((column: any) => ({...column, tickets: state.columns.find(c => c.columnId === column.columnId)?.tickets}))
  })),
  on(TicketsActions.updateTicketOrderSuccess, (state, {tickets}) => ({
    ...state,
    tickets: ticketsAdapter.setAll(tickets, {...state.tickets, loaded: true})
  })),
);

export function ticketsReducer(
  state: State | undefined,
  action: Action
) {
  return reducer(state, action);
}
