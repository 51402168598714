import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'razrooName',
    standalone: false
})
export class RazrooNamePipe implements PipeTransform {
  transform(obj: any): any {
    if (obj && obj['firstName'] && obj['lastName']) {
      return obj['firstName'] + ' ' + obj['lastName'];
    } else {
      if(obj && obj['email']) {
        return obj['email'];
      }
    }
  }
}
