import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HeaderModule } from '@razroo-zeta/header';
import { DataAccessModule } from '@razroo-zeta/data-access';
import { GraphQLModule } from './graphql.module';
import { AppRoutingModule } from './app-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { CommonPipesModule } from '@razroo-zeta/common/pipes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApolloModule } from 'apollo-angular';
import { CommentsModule } from '@razroo-zeta/comments';
import { NgxMaskModule } from 'ngx-mask';
import { PushPipe, LetDirective } from '@ngrx/component';
import { GoogleAnalyticsGtagComponent } from '@razroo-zeta/analytics';
import { ProgressBarComponent } from '../../../../libs/common/ui/src/lib/route-progress-bar/route-progress-bar';
import { WifejakChatComponent } from '@razroo-zeta/chat';

// intentionally kept light for tickets
const quillToolbar = [
  ['bold', 'italic', 'underline', 'strike'],
  ['code-block'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  ['link']
];

@NgModule({ declarations: [AppComponent], imports: [
        GraphQLModule,
        ProgressBarComponent,
        ApolloModule,
        AppRoutingModule,
        CommonPipesModule,
        DataAccessModule,
        BrowserAnimationsModule,
        GoogleAnalyticsGtagComponent,
        HeaderModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatTooltipModule,
        CommentsModule,
        NgxMaskModule.forRoot(),
        PushPipe,
        LetDirective, 
        WifejakChatComponent], providers: [
          provideHttpClient(withFetch()),
          provideHttpClient(withInterceptorsFromDi())
      ],
      exports: [
        WifejakChatComponent
      ]
     })
export class SharedModule {}
