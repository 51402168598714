import { createAction, props } from '@ngrx/store';
import { OrganizationUser, Ticket, TicketWorkspace, TicketWorkspaceTicketUpdateParams, CondensationItem, 
  UpdateTicketColumnParams, TicketWorkspaceBoardColumn, AbridgedTicket, CollectionUpdateParams } from '@razroo-zeta/data-models';
import { EstimatedStoryPointsReason, ClarificationQuestionResponse, 
  TicketWorkspaceBoard, MoveKanbanCardParamsInputAlt, TicketsSearchResult, EpicSuggestion, 
  DatasetCollection} from 'libs/data-models/src/lib/tickets/tickets.interface';

export const loadTicket = createAction(
  '[Tickets/API] Load Ticket',
  props<{ orgId: string, workspaceId: string, ticketNumber: number, noCache?: boolean }>()
);

export const loadTicketSuccess = createAction(
  '[Tickets/API] Load Ticket Success',
  props<{ ticket: Ticket }>()
);

export const loadTicketFailure = createAction(
  '[Tickets/API] Load Ticket Failure',
  props<{ error: any }>()
);

export const loadTickets = createAction(
  '[Tickets/API] Load Tickets',
  props<{ orgId: string, workspaceId: string, boardId?: number | undefined}>()
);

export const loadTicketsSuccess = createAction(
  '[Tickets/API] Load Tickets Success',
  props<{ tickets: Ticket[] }>()
);

export const loadTicketsFailure = createAction(
  '[Tickets/API] Load Tickets Failure',
  props<{ error: any }>()
);

export const selectTicket = createAction(
  '[Tickets/API] Select Ticket', 
  props<{ ticket: Ticket }>()
);

export const selectEpic = createAction(
  '[Tickets/API] Select Epic', 
  props<{ epic: Ticket }>()
);

export const generateProductTicketTitlesAndUpload = createAction(
  '[Tickets/API] Generate Product Ticket Titles And Upload',
  props<{orgId: string, workspaceId: string, currentEpicTickets: AbridgedTicket[], userProductMessage: string, 
    labels: string[], parentTicketNumber: number, epic: string, startingTicketNumber: number}>()
);

export const generateProductTicketTitlesAndUploadSuccess = createAction(
  '[Tickets/API] Generate Product Ticket Titles And Upload Success',
  props<{tickets: Ticket[], parentTicketNumber: number}>()
);
export const generateProductTicketTitlesAndUploadFailure = createAction(
  '[Tickets/API] Generate Product Ticket Titles And Upload Failure',
  props<{error: Error}>()
);

export const manuallyCreateTicket = createAction(
  '[Tickets/API] Manually Create Ticket',
  props<{ orgId: string, workspaceId: string, boardId: number, title: string, 
    parentTicketNumber?: number, columns?: TicketWorkspaceBoardColumn[]}>()
);

export const manuallyCreateTicketSuccess = createAction(
  '[Tickets/API] Manually Create Ticket Success',
  props<{ ticket: Ticket, columns?: TicketWorkspaceBoardColumn[] }>()
);

export const manuallyCreateTicketFailure = createAction(
  '[Tickets/API] Manually Create Ticket Failure',
  props<{ error: any }>() 
);


export const generateEntireProductTicketUsingAiAndUpload = createAction(
  '[Tickets/API] Generate Entire Product Ticket Using Ai And Upload',
  props<{user: OrganizationUser, orgId: string, workspaceId: string, userTicketPrompt: string, epic?: string, 
    boardId?: number, clarificationQuestionResponse?: ClarificationQuestionResponse[], columns?: TicketWorkspaceBoardColumn[], 
    board?: TicketWorkspaceBoard, parentTicketNumber?: number, labels: string[]}>()
);

export const generateEntireProductTicketUsingAiAndUploadSuccess = createAction(
  '[Tickets/API] Generate Entire Product Ticket Using Ai And Upload Success',
  props<{ticket: Ticket, columns?: TicketWorkspaceBoardColumn[], board?: TicketWorkspaceBoard}>()
);
export const generateEntireProductTicketUsingAiAndUploadFailure = createAction(
  '[Tickets/API] Generate Entire Product Ticket Using Ai And Upload Failure',
  props<{error: Error}>()
);

export const generateEntireProductTicketUsingAiAndUpdate = createAction(
  '[Tickets/API] Generate Entire Product Ticket Using AI And Update',
  props<{ orgId: string, workspaceId: string, ticketNumber: number, columns: TicketWorkspaceBoardColumn[] }>()
);

export const generateEntireProductTicketUsingAiAndUpdateSuccess = createAction(
  '[Tickets/API] Generate Entire Product Ticket Using AI And Update Success',
  props<{ ticket: Ticket }>()
);

export const generateEntireProductTicketUsingAiAndUpdateFailure = createAction(
  '[Tickets/API] Generate Entire Product Ticket Using AI And Update Failure',
  props<{ error: Error }>()
);


export const generateEntireProductTicketForKanbanUsingAiAndUpload = createAction(
  '[Tickets/API] Generate Entire Product Ticket For Kanban Using Ai And Upload',
  props<{user: OrganizationUser, orgId: string, workspaceId: string, userTicketPrompt: string, epic?: string, boardId?: number, clarificationQuestionResponse?: ClarificationQuestionResponse[]}>()
);

export const generateEntireProductTicketForKanbanUsingAiAndUploadSuccess = createAction(
  '[Tickets/API] Generate Entire Product Ticket For Kanban Using Ai And Upload Success',
  props<{ticket: Ticket}>()
);
export const generateEntireProductTicketForKanbanUsingAiAndUploadFailure = createAction(
  '[Tickets/API] Generate Entire Product Ticket For Kanban Using Ai And Upload Failure',
  props<{error: Error}>()
);

export const deleteTicket = createAction(
  '[Tickets/API] Delete Ticket',
  props<{ orgId: string, workspaceId: string, boardId: number, 
    ticketNumber: number, ticketTitle: string, board: TicketWorkspaceBoard}>()
);

export const deleteTicketSuccess = createAction(
  '[Tickets/API] Delete Ticket Success',
  props<{ ticket: Ticket, board: TicketWorkspaceBoard }>()
);

export const deleteTicketFailure = createAction(
  '[Tickets/API] Delete Ticket Failure',
  props<{ error: any }>()
);

export const createCommunityTicketWorkspace = createAction(
  '[Tickets/API] Create Community Ticket Workspace',
  props<{ workspaceName: string, description: string}>()
);

export const createCommunityTicketWorkspaceSuccess = createAction(
  '[Tickets/API] Create Community Ticket Workspace Success',
  props<{ workspace: TicketWorkspace}>()
);

export const createCommunityTicketWorkspaceFailure = createAction(
  '[Tickets/API] Create Community Ticket Workspace Failure',
  props<{ error: Error}>()
);

export const createCommunityProjectsCollection = createAction(
  '[Tickets/API] Create Community Projects Collection',
  props<{ collectionName: string, description: string}>()
);

export const createCommunityProjectsCollectionSuccess = createAction(
  '[Tickets/API] Create Community Projects Collection Success',
  props<{ datasetCollection: DatasetCollection}>()
);

export const createCommunityProjectsCollectionFailure = createAction(
  '[Tickets/API] Create Community Projects Collection Failure',
  props<{ error: Error}>()
);

export const generateDescriptionForProductTicketAndUpdate = createAction(
  '[Tickets/API] Generate Description For Produc tTicket And Update',
  props<{ ticket: Ticket }>()
)
export const generateDescriptionForProductTicketAndUpdateSuccess = createAction(
  '[Tickets/API] Generate Description For Product Ticket And Update Success',
  props<{ ticket: Ticket }>()
)
export const generateDescriptionForProductTicketAndUpdateFailure = createAction(
  '[Tickets/API] Generate Description For Product Ticket And Update Failure',
  props<{ error: Error }>()
)

export const addBackgroundPlusTechnicalNotes = createAction(
  '[Tickets/API] Add Background Plus Technical Notes',
  props<{ticket: Ticket}>()
)

export const addBackgroundPlusTechnicalNotesPart1Success = createAction(
  '[Tickets/API] Add Background Plus Technical Notes Part 1 Success',
  props<{ticket: Ticket}>()
)

export const addBackgroundPlusTechnicalNotesPart2Success = createAction(
  '[Tickets/API] Add Background Plus Technical Notes Part 2 Success',
  props<{ticket: Ticket}>()
)

export const addBackgroundPlusTechnicalNotesFailure = createAction(
  '[Tickets/API] Add Background Plus Technical Notes Failure',
  props<{error: Error}>()
)

export const addTicketScenario = createAction(
  '[Tickets/API] Add Ticket Scenario', 
  props<{orgId: string, workspaceId: string, ticketNumber: number, description: string, userPrompt: string}>()
)
export const addTicketScenarioSuccess = createAction(
  '[Tickets/API] Add Ticket Scenario Success', 
  props<{ticket: {orgId: string, workspaceId: string, ticketNumber: number}, newDescription: string, condensations: CondensationItem[]}>()
)
export const addTicketScenarioFailure = createAction(
  '[Tickets/API] Add Ticket Scenario Failure', 
  props<{error: Error}>()
)

export const addAiToTicketDescription = createAction(
  '[Tickets/API] Add Ai To Ticket Description',
  props<{orgId: string, ticket: Ticket, description: string, generationType: string}>()
)
export const addAiToTicketDescriptionSuccess = createAction(
  '[Tickets/API] Add Ai To Ticket Description Success',
  props<{ticket: Ticket}>()
)

export const addTicketCodeSnippetSuccess = createAction(
  '[Tickets/API] Add Ticket Code Snippet Success',
  props<{ticket: Ticket}>()
)

export const addAiToTicketDescriptionFailure = createAction(
  '[Tickets/API] Add Ai To Ticket Description Failure',
  props<{error: Error}>()
)

export const updateTicketWorkspaceTicket = createAction(
  '[Tickets/API] Update Ticket Workspace Ticket',
  props<{orgId: string, ticket: Ticket, ticketUpdateParams: TicketWorkspaceTicketUpdateParams}>()
);

export const updateTicketWorkspaceTicketSuccess = createAction(
  '[Tickets/API] Update Ticket Workspace Ticket Success',
  props<{orgId: string, ticket: Ticket, ticketUpdateParams: TicketWorkspaceTicketUpdateParams}>()
);

export const updateTicketWorkspaceTicketFailure = createAction(
  '[Tickets/API] Update Ticket Workspace Ticket Failure',
  props<{ error: any }>() 
);

export const modifyTicketBasedOnTitleUsingAi = createAction(
  '[Tickets/API] Modify Ticket Based On Title Using Ai',
  props<{orgId: string, workspaceId: string, ticketNumber: number, 
    existingDescription: string, existingTitle: string, updatedTitle: string}>()
);

export const modifyTicketBasedOnTitleUsingAiSuccess = createAction(
  '[Tickets/API] Modify Ticket Based On Title Using Ai Success',
  props<{ticket: Ticket}>()
);

export const modifyTicketBasedOnTitleUsingAiFailure = createAction(
  '[Tickets/API] Modify Ticket Based On Title Using Ai Failure',
  props<{ error: any }>() 
);

export const updateWorkspaceDataTableTicketColumn = createAction(
  '[Tickets/API] Update Workspace Data Table Ticket Column',
  props<{orgId: string, workspaceId: string, ticketNumber: number, 
    ticketUpdateParams: UpdateTicketColumnParams, board: TicketWorkspaceBoard, updatedTickets: Ticket[]}>()
);

export const updateWorkspaceDataTableTicketColumnSuccess = createAction(
  '[Tickets/API] Update Workspace Data Table  Ticket Column Success',
  props<{orgId: string, workspaceId: string, ticketNumber: number, 
    ticketUpdateParams: UpdateTicketColumnParams}>()
);

export const updateWorkspaceDataTableTicketColumnFailure = createAction(
  '[Tickets/API] Update Workspace Data Table Ticket Column Failure',
  props<{ error: any }>() 
);

export const updateWorkspaceTicketColumn = createAction(
  '[Tickets/API] Update Workspace Ticket Column',
  props<{orgId: string, workspaceId: string, ticketNumber: number, ticketUpdateParams: UpdateTicketColumnParams}>()
);

export const updateWorkspaceTicketColumnSuccess = createAction(
  '[Tickets/API] Update Workspace Ticket Column Success',
  props<{orgId: string, workspaceId: string, ticketNumber: number, 
    ticketUpdateParams: UpdateTicketColumnParams, ticket: Ticket}>()
);

export const updateWorkspaceTicketColumnFailure = createAction(
  '[Tickets/API] Update Workspace Ticket Column Failure',
  props<{ error: any }>() 
);

export const uploadJiraApiKeyAndJiraOrg = createAction(
  '[Tickets/API] Upload Jira Api Key And Jira Org',
  props<{orgId: string, jiraOrgId: string, jiraApiKey: string}>()
);

export const uploadJiraApiKeyAndJiraOrgSuccess = createAction(
  '[Tickets/API] Upload Jira Api Key And Jira Org Success',
  props<{ jiraOrgId: string }>()
);

export const uploadJiraApiKeyAndJiraOrgFailure = createAction(
  '[Tickets/API] Upload Jira Api Key And Jira Org Failure',
  props<{ error: any }>() 
);

export const uploadTicketToJira = createAction(
  '[Tickets/API] Upload Ticket To Jira',
  props<{jiraInstance: string, ticketWorkspace: TicketWorkspace, ticket: Ticket}>()
);

export const uploadTicketToJiraSuccess = createAction(
  '[Tickets/API] Upload Ticket To Jira Success',
  props<{ ticket: Ticket }>()
);

export const uploadTicketToJiraFailure = createAction(
  '[Tickets/API] Upload Ticket To Jira Failure',
  props<{ error: any }>() 
);

export const loadTicketHistory = createAction(
  '[Tickets/API] Load Ticket History',
  props<{orgId: string, workspaceId: string, ticketNumber: number}>()
);

export const loadTicketHistorySuccess = createAction(
  '[Tickets/API] Load Ticket History Success',
  props<{ ticketHistory: Ticket[] }>()
);

export const loadTicketHistoryFailure = createAction(
  '[Tickets/API] Load Ticket History Failure',
  props<{ error: any }>() 
);

export const loadTicketHistoryItem = createAction(
  '[Tickets/API] Load Ticket History Item',
  props<{historyItem: Ticket}>()
);

export const generateUserJourney = createAction(
  '[Tickets/API] Generate User Journey',
  props<{ticket: Ticket}>()
);

export const generateUserJourneySuccess = createAction(
  '[Tickets/API] Generate User Journey Success',
  props<{ userJourney: any, ticket: Ticket }>()
);

export const generateUserJourneyFailure = createAction(
  '[Tickets/API] Generate User Journey Failure',
  props<{ error: any }>()
);
export const generateAcceptanceCriteriaForProductTicket = createAction(
  '[Tickets/API] Generate Acceptance Criteria For Product Ticket',
  props<{ticket: Ticket}>()
);

export const generateAcceptanceCriteriaForProductTicketSuccess = createAction(
  '[Tickets/API] Generate Acceptance Criteria For Product Ticket Success',
  props<{ acceptanceCriteria: string, ticket: Ticket }>()
);

export const generateAcceptanceCriteriaForProductTicketFailure = createAction(
  '[Tickets/API] Generate Acceptance Criteria For Product Ticket Failure',
  props<{ error: any }>()
);

export const generateBusinessValueForProductTicket = createAction(
  '[Tickets/API] Generate Business Value For Product Ticket',
  props<{ ticket: Ticket }>()
);

export const generateBusinessValueForProductTicketSuccess = createAction(
  '[Tickets/API] Generate Business Value For Product Ticket Success',
  props<{ businessValue: any, ticket: Ticket }>()
);

export const generateBusinessValueForProductTicketFailure = createAction(
  '[Tickets/API] Generate Business Value For Product Ticket Failure',
  props<{ error: any }>()
);
export const generateEstimatedStoryPointsReasonForProductTicket = createAction(
  '[Tickets/API] Generate Estimated Story Points Reason For Product Ticket',
  props<{ticket: Ticket}>()
);
export const generateEstimatedStoryPointsReasonForProductTicketSuccess = createAction(
  '[Tickets/API] Generate Estimated Story Points Reason For Product Ticket Success',
  props<{estimatedStoryPointsReason: EstimatedStoryPointsReason, ticket: Ticket}>()
);
export const generateEstimatedStoryPointsReasonForProductTicketFailure = createAction(
  '[Tickets/API] Generate Estimated Story Points Reason For Product Ticket Failure',
  props<{ error: any }>() 
);
export const updateTicketSprint = createAction(
  '[Tickets/API] Update Ticket Sprint',
  props<{orgId: string, ticket: Ticket, sprint: TicketWorkspaceBoard}>()
);
export const updateTicketSprintSuccess = createAction(
  '[Tickets/API] Update Ticket Sprint Success',
  props<{orgId: string, ticket: Ticket, sprint: TicketWorkspaceBoard}>()
);
export const updateTicketSprintFailure = createAction(
  '[Tickets/API] Update Ticket Sprint Failure',
  props<{ error: any }>() 
);

export const moveCardInKanbanBoard = createAction(
  '[Tickets/API] Move Card In Kanban Board',
  props<{orgId: string, workspaceId: string, boardId?: number, columnId?: string, ticketNumber?: number, moveKanbanCardParams?: MoveKanbanCardParamsInputAlt, newlyArrangedKanban?: any}>()
);

export const moveCardInKanbanBoardSuccess = createAction(
  '[Tickets/API] Move Card In Kanban Board Success',
  props<{ orgId: string, workspaceId: string, boardId?: number, moveKanbanCardParams?: MoveKanbanCardParamsInputAlt}>()
);

export const moveCardInKanbanBoardFailure = createAction(
  '[Tickets/API] Move Card In Kanban Board Failure',
  props<{ error: any }>() 
);

export const noAction = createAction(
  '[Tickets/API] No Action'
)

export const addAttachmentsToTicket = createAction(
  '[Tickets/API] Add Attachments To Ticket',
  props<{ticket: Ticket, attachments: string[]}>()
);

export const removeTicketAttachment = createAction(
  '[Tickets/API] Remove Ticket Attachment',
  props<{ticket: Ticket, attachmentFileName: string}>()
);

export const removeTicketAttachmentSuccess = createAction(
  '[Tickets/API] Remove Ticket Attachment Success',
  props<{ticket: Ticket}>()
);

export const removeTicketAttachmentFailure = createAction(
  '[Tickets/API] Remove Ticket Attachment Failure',
  props<{error: Error}>()
);

export const subscribeToTicket = createAction(
  '[Tickets/API] Subscribe To Ticket',
  props<{ticket: Ticket}>()
);
export const subscribeToTicketSuccess = createAction(
  '[Tickets/API] Subscribe To Ticket Success',
  props<{ticket: Ticket}>()
);
export const subscribeToTicketFailure = createAction(
  '[Tickets/API] Subscribe To Ticket Failure',
  props<{error: Error}>()
);

export const unsubscribeFromTicket = createAction(
  '[Tickets/API] Unsubscribe From Ticket',
  props<{ticket: Ticket}>()
);
export const unsubscribeFromTicketSuccess = createAction(
  '[Tickets/API] Unsubscribe From Ticket Success',
  props<{ticket: Ticket}>()
);
export const unsubscribeFromTicketFailure = createAction(
  '[Tickets/API] Unsubscribe From Ticket Failure',
  props<{error: Error}>()
);

export const renameKanbanColumn = createAction(
  '[Tickets/API] Rename Kanban Column',
  props<{orgId: string, workspaceId: string, columnId: string, newColumnName: string, boardId: number}>()
);

export const renameKanbanColumnSuccess = createAction(
  '[Tickets/API] Rename Kanban Column Success',
  props<{columnId: string, newColumnName: string}>()
);

export const renameKanbanColumnFailure = createAction(
  '[Tickets/API] Rename Kanban Column Failure',
  props<{error: Error}>()
);

export const createKanbanColumn = createAction(
  '[Tickets/API] Create Kanban Column',
  props<{orgId: string, workspaceId: string, columnName: string, boardId: number}>()
);

export const createKanbanColumnSuccess = createAction(
  '[Tickets/API] Create Kanban Column Success',
  props<{columns: any}>()
);

export const createKanbanColumnFailure = createAction(
  '[Tickets/API] Create Kanban Column',
  props<{error: Error}>()
);

export const deleteKanbanColumn = createAction(
  '[Tickets/API] Delete Kanban Column',
  props<{orgId: string, workspaceId: string, columnId: string, newColumnId?: string, boardId?: number}>()
);

export const deleteKanbanColumnSuccess = createAction(
  '[Tickets/API] Delete Kanban Column Success',
  props<{ticketWorkspaceBoard: any}>()
);

export const deleteKanbanColumnFailure = createAction(
  '[Tickets/API] Delete Kanban Column Failure',
  props<{error: Error}>()
);

export const reorderKanbanColumns = createAction(
  '[Tickets/API] Reorder Kanban Columns',
  props<{orgId: string, workspaceId: string, boardColumns: string[], boardId: number}>()
);

export const reorderKanbanColumnsSuccess = createAction(
  '[Tickets/API] Reorder Kanban Columns Success',
  props<{columnsRes: any}>()
);

export const reorderKanbanColumnsFailure = createAction(
  '[Tickets/API] Reorder Kanban Columns Failure',
  props<{error: Error}>()
);

export const updateTicketOrder = createAction(
  '[Tickets/API] Update Ticket Order',
  props<{orgId: string, workspaceId: string, ticketsOrder: number[], boardId: number, tickets: Ticket[]}>()
);

export const updateTicketOrderSuccess = createAction(
  '[Tickets/API] Update Ticket Order Success',
  props<{tickets: any}>()
);

export const updateTicketOrderFailure = createAction(
  '[Tickets/API] Update Ticket Order Failure',
  props<{error: Error}>()
);

export const searchTickets = createAction(
  '[Tickets/API] Search Tickets',
  props<{search: string, searchFields: string[], orgId: string, workspaceId: string, size: number, fromNum: number}>()
);

export const searchTicketsSuccess = createAction(
  '[Tickets/API] Search Tickets Success',
  props<{ticketsSearchResult: TicketsSearchResult}>()
);

export const searchTicketsFailure = createAction(
  '[Tickets/API] Search Tickets Failure',
  props<{error: Error}>()
);

export const searchTicketWorkspaces = createAction(
  '[Tickets/API] Search Ticket Workspaces',
  props<{search: string, searchFields: string[], orgId: string, workspaceId: string, size: number, fromNum: number}>()
);

export const searchTicketWorkspacesSuccess = createAction(
  '[Tickets/API] Search Ticket Workspaces Success',
  props<{ticketWorkspaces: TicketWorkspace[], totalHits: number}>()
);

export const searchTicketWorkspacesFailure = createAction(
  '[Tickets/API] Search Ticket Workspaces Failure',
  props<{error: Error}>()
);

export const unlinkParentTicketFromTicket = createAction(
  '[Tickets/API] Unlink Parent Ticket From Ticket',
  props<{orgId: string, workspaceId: string, ticketNumber: number}>()
);

export const unlinkParentTicketFromTicketSuccess = createAction(
  '[Tickets/API] Unlink Parent Ticket From Ticket Success',
  props<{ticket: Ticket}>()
);

export const unlinkParentTicketFromTicketFailure = createAction(
  '[Tickets/API] Unlink Parent Ticket From Ticket Failure',
  props<{error: Error}>()
);

export const getAllTicketWorkspaceEpics = createAction(
  '[Tickets/API] Get All Ticket Workspace Epics',
  props<{orgId: string, workspaceId: string}>()
);

export const getAllTicketWorkspaceEpicsSuccess = createAction(
  '[Tickets/API] Get All Ticket Workspace Epics Success',
  props<{epics: Ticket[]}>()
);

export const getAllTicketWorkspaceEpicsFailure = createAction(
  '[Tickets/API] Get All Ticket Workspace Epics Failure',
  props<{error: Error}>()
);

export const getCommunityTicketWorkspaces = createAction(
  '[Tickets/API] Get Community Ticket Workspaces'
);

export const getCommunityTicketWorkspacesSuccess = createAction(
  '[Tickets/API] Get Community Ticket Workspaces Success',
  props<{ticketWorkspaces: TicketWorkspace[]}>()
);

export const getCommunityTicketWorkspacesFailure = createAction(
  '[Tickets/API] Get Community Ticket Workspaces Failure',
  props<{error: Error}>()
);

export const createEpicProductTicketUsingAi = createAction(
  '[Tickets/API] Create Epic Product Ticket Using Ai',
  props<{orgId: string, workspaceId: string, userTicketPrompt: string}>()
);

export const createEpicProductTicketUsingAiSuccess = createAction(
  '[Tickets/API] Create Epic Product Ticket Using Ai Success',
  props<{epic: Ticket}>()
);

export const createEpicProductTicketUsingAiFailure = createAction(
  '[Tickets/API] Create Epic Product Ticket Using Ai Failure',
  props<{error: Error}>()
);

export const createEpicProductTicketFromEpicSuggestion = createAction(
  '[Tickets/API] Create Epic Product Ticket From Epic Suggestion',
  props<{orgId: string, workspaceId: string, epicSuggestion: EpicSuggestion}>()
);

export const createEpicProductTicketFromEpicSuggestionSuccess = createAction(
  '[Tickets/API] Create Epic Product Ticket From Epic Suggestion Success',
  props<{epic: Ticket, epicSuggestion: EpicSuggestion}>()
);

export const createEpicProductTicketFromEpicSuggestionFailure = createAction(
  '[Tickets/API] Create Epic Product Ticket From Epic Suggestion Failure',
  props<{error: Error}>()
);
// -- epicFromEpicSuggestionViaDocumentUpload created to make workflow for document upload more manageable
export const epicFromEpicSuggestionViaDocumentUpload = createAction(
  '[Tickets/API] Epic From Epic Suggestion Via Document Upload',
  props<{orgId: string, workspaceId: string, epicSuggestion: EpicSuggestion, ticketWorkspace: TicketWorkspace}>()
);

export const epicFromEpicSuggestionViaDocumentUploadSuccess = createAction(
  '[Tickets/API] Epic From Epic Suggestion Via Document Upload Success',
  props<{epic: Ticket, epicSuggestion: EpicSuggestion, ticketWorkspace: TicketWorkspace}>()
);

export const epicFromEpicSuggestionViaDocumentUploadFailure = createAction(
  '[Tickets/API] Epic From Epic Suggestion Via Document Upload Failure',
  props<{error: Error}>()
);

export const getAllTitleTicketsForWorkspaceEpic = createAction(
  '[Tickets/API] Get All Title Tickets For Workspace Epic',
  props<{orgId: string, workspaceId: string, parentTicketNumber?: number}>()
);

export const getAllTitleTicketsForWorkspaceEpicSuccess = createAction(
  '[Tickets/API] Get All Title Tickets For Workspace Epic Success',
  props<{tickets: Ticket[]}>()
);

export const getAllTitleTicketsForWorkspaceEpicFailure = createAction(
  '[Tickets/API] Get All Title Tickets For Workspace Epic Failure',
  props<{error: Error}>()
);

export const deleteTicketTitle = createAction(
  '[Tickets/API] Delete Ticket Title',
  props<{orgId: string, workspaceId: string, parentTicketNumber: number, ticketNumber: number}>()
);

export const deleteTicketTitleSuccess = createAction(
  '[Tickets/API] Delete Ticket Title Success',
  props<{ticket: Ticket}>()
);

export const deleteTicketTitleFailure = createAction(
  '[Tickets/API] Delete Ticket Title Failure',
  props<{error: Error}>()
);

export const ticketTitleGenerating = createAction(
  '[Tickets/API] Ticket Title Generating',
  props<{ticket: Ticket}>()
);

export const ticketTitleGeneratingError = createAction(
  '[Tickets/API] Ticket Title Generating Error',
  props<{ticket: Ticket}>()
);

export const generateEpicsFromDocumentsUsingAiSuccess = createAction(
  '[Tickets/API] Generate Epics From Documents Using Ai Success',
  props<{epicSuggestions: EpicSuggestion[]}>()
);

export const suggestNewEpicsSuccess = createAction(
  '[Tickets/API] Suggest New Epics Success',
  props<{epicSuggestions: EpicSuggestion[]}>()
);

export const updateKanbanCard = createAction(
  '[Tickets/API] Update Kanban Card',
  props<{orgId: string, workspaceId: string, boardId: number, columnId: string, ticketNumber: number, ticket: Ticket}>()
);

export const searchProjectsDatasetCollections = createAction(
  '[Tickets/API] Search Projects Dataset Collections',
  props<{search?: string, searchFields?: string[], orgId?: string, size?: number, fromIndex?: number}>()
);

export const searchProjectsDatasetCollectionsSuccess = createAction(
  '[Tickets/API] Search Projects Dataset Collections Success',
  props<{datasetCollections: DatasetCollection[]}>()
);

export const searchProjectsDatasetCollectionsFailure = createAction(
  '[Tickets/API] Search Projects Dataset Collections Failure',
  props<{error: Error}>()
);

export const updateProjectsDatasetCollection = createAction(
  '[Tickets/API] Update Projects Dataset Collection',
  props<{orgId: string, collectionId: string, collectionUpdateParams: CollectionUpdateParams}>()
);

export const updateProjectsDatasetCollectionSuccess = createAction(
  '[Tickets/API] Update Projects Dataset Collection Success',
  props<{datasetCollection: DatasetCollection}>()
);

export const updateProjectsDatasetCollectionFailure = createAction(
  '[Tickets/API] Update Projects Dataset Collection Failure',
  props<{error: Error}>()
);

export const getProjectDatasetCollection = createAction(
  '[Tickets/API] Get Project Dataset Collection',
  props<{orgId: string, collectionId: string}>()
);

export const getProjectDatasetCollectionSuccess = createAction(
  '[Tickets/API] Get Project Dataset Collection Success', 
  props<{datasetCollection: DatasetCollection}>()
);

export const getProjectDatasetCollectionFailure = createAction(
  '[Tickets/API] Get Project Dataset Collection Failure',
  props<{error: Error}>()
);
