import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { GithubRepo, OrganizationUser, Template } from '@razroo-zeta/data-models';
import { StepperScrollerComponentModule } from 'libs/ui/common/src/lib/stepper-scroller/stepper-scroller.component';
import { UserFacade } from '@razroo-zeta/data-access';
import { LetDirective } from '@ngrx/component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { GenerateCodeService } from '@razroo-zeta/data-services';
import { Subject, takeUntil } from 'rxjs';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatCardModule } from '@angular/material/card';
import { CorePathIdPipe } from '@razroo-zeta/data-pipes';

@Component({
    selector: 'zeta-frontend-execute-code-dialog',
    templateUrl: './execute-code-dialog.component.html',
    styleUrls: ['./execute-code-dialog.component.scss'],
    standalone: false
})
export class ExecuteCodeDialogComponent implements OnInit {
  user$ = this.userFacade.currentUser$;
  executeInformationOpen = false;
  unsubscribe$: Subject<string> = new Subject();
  githubRepos: GithubRepo[];
  githubRepo: GithubRepo | undefined;
  githubRepoName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      template: Template,
      user: OrganizationUser
    },
    private dialogRef: MatDialogRef<ExecuteCodeDialogComponent>,
    private userFacade: UserFacade,
    private generateCodeService: GenerateCodeService,
    private corePathIdPipe: CorePathIdPipe
  ) {}

  ngOnInit(): void { 
    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe(user => {
      this.githubRepos = user?.githubRepos as GithubRepo[];
      if(this.data.user.activeProject){
        let coreId = this.corePathIdPipe.transform(this.data.user.activeProject!.id)
        let activeProjectRepo = this.githubRepos.find(repo => repo.name === coreId)
        if(activeProjectRepo) {
          this.githubRepo = activeProjectRepo;
        }
      }
    })
    this.githubRepos = this.data.user.githubRepos;
  }

  selectGithubRepo(githubRepo: GithubRepo) {
    if(githubRepo){
      this.githubRepo = githubRepo;
    }
  }

  filterGithubRepos($event) {
    this.githubRepo = undefined;
    const githubRepoName = $event.target.value;
    this.githubRepoName = githubRepoName;
    this.githubRepos = this.data.user.githubRepos.filter(githubRepo => {
      if (githubRepo.name) {
        return githubRepo.name.toLowerCase().includes(githubRepoName.toLowerCase());
      }
      return []; // Handle the case where name is null or undefined
    });
  }

  getGithubRepos(user: OrganizationUser) {
    this.generateCodeService.getGithubRepos(user.userId, user.orgId, user.githubOrg as string);
  }

  executeCode(githubRepo: GithubRepo) {
    this.dialogRef.close({githubRepo, githubRepoName: this.githubRepoName});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next('true');
    this.unsubscribe$.complete();
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    LetDirective,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    StepperScrollerComponentModule,
    OverlayModule,
    MatCardModule
  ],
  declarations: [ExecuteCodeDialogComponent],
  exports: [ExecuteCodeDialogComponent],
})
export class ExecuteCodeComponentModule {}
