import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
@Component({
    selector: 'razroo-zeta-comment-reply',
    templateUrl: './comment-reply.component.html',
    styleUrls: ['./comment-reply.component.scss'],
    standalone: false
})
export class CommentReplyComponent implements OnInit {
  @Output() postReply = new EventEmitter();
  showForm = false;
  constructor(
    private fb: UntypedFormBuilder
  ) {}
  addReplyForm = this.fb.group({
    replyText: ['']
  });
  cancel(){
    this.showForm = false;
    this.addReplyForm.reset()
  }
  post(){
    if(this.addReplyForm.value.replyText){
      this.postReply.emit(this.addReplyForm.value.replyText)
    }
  }
  ngOnInit(): void {}
}
