import { Pipe, PipeTransform } from '@angular/core';
import { Organization, User } from '@razroo-zeta/data-models';

@Pipe({
    name: 'orgName',
    standalone: false
})
export class OrgNamePipe implements PipeTransform {

  transform(organization: Organization, userProfile: User): string {
    if(organization && userProfile && organization.orgId === userProfile.userId) {
      return userProfile.email;
    } else {
      return organization && organization.displayName ? organization.displayName : organization.orgId
    }
  }

}
