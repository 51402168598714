import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as TicketWorkspacesActions from './ticket-workspaces.actions';
import { TicketWorkspaceBoardEntity, TicketWorkspacesEntity } from './ticket-workspaces.models';
import { TicketWorkspace, TicketWorkspaceBoard } from '@razroo-zeta/data-models';

export const TICKET_WORKSPACES_FEATURE_KEY = 'ticketWorkspaces';

export interface TicketWorkspacesState extends EntityState<TicketWorkspacesEntity> {
  selectedId?: string | number; // which TicketWorkspaces record has been selected
  loaded: boolean; // has the TicketWorkspaces list been loaded
  boardsLoaded: boolean; // has the TicketWorkspace boards been loaded
  ticketWorkspaceBoards: any;
  selectedBoardId: number | undefined; // will change when a new board selected
  error?: string | null; // last known error (if any)
}

export interface TicketWorkspaceBoardState extends EntityState<TicketWorkspaceBoardEntity> {
  selectedId?: string | number; // which TicketWorkspaces record has been selected
  loaded?: boolean; // has the TicketWorkspaces list been loaded
  error?: string | null; // last known error (if any)
}

export interface TicketWorkspacesPartialState {
  readonly [TICKET_WORKSPACES_FEATURE_KEY]: TicketWorkspacesState;
}

export function selectWorkspaceId(a: TicketWorkspace): string {
  //In this case this would be optional since primary key is id
  return a.workspaceId;
}

export function selectWorkspaceBoardId(a: TicketWorkspaceBoard): number {
  //In this case this would be optional since primary key is id
  return a.boardId;
}

export const ticketWorkspacesAdapter: EntityAdapter<TicketWorkspacesEntity> =
  createEntityAdapter<TicketWorkspacesEntity>({
    selectId: selectWorkspaceId
  });

export const ticketWorkspaceBoardsAdapter: EntityAdapter<TicketWorkspaceBoardEntity> =
  createEntityAdapter<TicketWorkspaceBoardEntity>({
    selectId: selectWorkspaceBoardId
  });  

export const initialTicketWorkspaceBoardState: TicketWorkspaceBoardState =
  ticketWorkspaceBoardsAdapter.getInitialState({
    // set initial required properties,
    selectedBoardId: undefined,
    loaded: false,
  });

export const initialTicketWorkspacesState: TicketWorkspacesState =
  ticketWorkspacesAdapter.getInitialState({
    // set initial required properties,
    selectedBoardId: undefined,
    ticketWorkspaceBoards: initialTicketWorkspaceBoardState,
    boardsLoaded: false,
    loaded: false,
  });
  

const reducer = createReducer(
  initialTicketWorkspacesState,
  on(TicketWorkspacesActions.loadTicketWorkspaces, (state) => ({
    ...state,
    loaded: false,
    boardsLoaded: false,
    error: null,
  })),
  on(TicketWorkspacesActions.setActiveTicketWorkspaceBoard, (state, {boardId}) => ({
    ...state,
    selectedBoardId: boardId,
  })),
  on(TicketWorkspacesActions.loadTicketWorkspacesSuccess, (state, { ticketWorkspaces }) => {
    ticketWorkspaces = ticketWorkspaces.filter(workspace => workspace.workspaceId !== state.selectedId);
    return ticketWorkspacesAdapter.upsertMany(ticketWorkspaces, { ...state, loaded: true });
  }),
  on(TicketWorkspacesActions.loadTicketWorkspaceSuccess, (state, { ticketWorkspace }) =>
    ticketWorkspacesAdapter.upsertOne(ticketWorkspace, { ...state, loaded: true, selectedId: ticketWorkspace.workspaceId })
  ),
  on(TicketWorkspacesActions.createTicketWorkspaceSuccess, (state, { ticketWorkspace }) =>
    ticketWorkspacesAdapter.addOne(ticketWorkspace, { ...state, loaded: true })
  ),
  on(TicketWorkspacesActions.updateTicketWorkspaceSuccess, (state, { ticketWorkspace }) =>
    ticketWorkspacesAdapter.updateOne({id: ticketWorkspace.workspaceId, changes: ticketWorkspace}, { ...state, loaded: true })
  ),
  on(TicketWorkspacesActions.updateTicketWorkspaceFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error,
  })),
  on(TicketWorkspacesActions.deleteTicketWorkspaceSuccess, (state, { ticketWorkspace }) =>
    ticketWorkspacesAdapter.removeOne(ticketWorkspace.workspaceId, { ...state, loaded: true })
  ),
  on(TicketWorkspacesActions.createTicketWorkspaceBoardSuccess, (state, {ticketWorkspaceBoard}) =>({
    ...state,
    ticketWorkspaceBoards: ticketWorkspaceBoardsAdapter.addOne(ticketWorkspaceBoard, {
      ...state.ticketWorkspaceBoards,
      loaded: true
    })
  })),
  on(TicketWorkspacesActions.getTicketWorkspaceBoardSuccess, (state, { ticketWorkspaceBoard }) => ({
    ...state,
    ticketWorkspaceBoards: ticketWorkspaceBoardsAdapter.upsertOne(ticketWorkspaceBoard, {
      ...state.ticketWorkspaceBoards,
      loaded: true
    })
  })),
  on(TicketWorkspacesActions.loadTicketWorkspaceSprintBoards, (state) => ({
    ...state,
    ticketWorkspaceBoards: ticketWorkspaceBoardsAdapter.setAll([], {
      ...state.ticketWorkspaceBoards,
      ids: [],
      entities: {},
      loaded: false
    })
  })),
  on(TicketWorkspacesActions.loadTicketWorkspaceSprintBoardsSuccess, (state, { boards }) =>({
    ...state,
    ticketWorkspaceBoards: ticketWorkspaceBoardsAdapter.setAll(boards, {
      ...state.ticketWorkspaceBoards,
      loaded: true
    })
  })),
  on(TicketWorkspacesActions.getCompletedWorkspaceBoardsSuccess, (state, { ticketWorkspaceBoards }) =>({
    ...state,
    ticketWorkspaceBoards: ticketWorkspaceBoardsAdapter.setAll(ticketWorkspaceBoards, {
      ...state.ticketWorkspaceBoards,
      loaded: true
    })
  })),
  on(TicketWorkspacesActions.updateTicketWorkspaceBoardSuccess, (state, { ticketWorkspaceBoard, completeSprint }) =>{
    return {
      ...state,
      loaded: true,
      ticketWorkspaceBoards: ticketWorkspaceBoardsAdapter.updateOne(
        {
          id: ticketWorkspaceBoard.boardId, 
          changes: {
            ...ticketWorkspaceBoard,
          }
      },
      { ...state.ticketWorkspaceBoards, loaded: true }
      )
    }
  }),
  on(TicketWorkspacesActions.deleteTicketWorkspaceBoardSuccess, (state, {ticketWorkspaceBoard}) => ({
    ...state,
    ticketWorkspaceBoards: ticketWorkspaceBoardsAdapter.removeOne(ticketWorkspaceBoard.boardId, {
      ...state.ticketWorkspaceBoards, selectedId: undefined})
  })),
  on(TicketWorkspacesActions.addUserToTicketWorkspaceSuccess, (state, {ticketWorkspace}) => 
    ticketWorkspacesAdapter.updateOne({id: ticketWorkspace.workspaceId, changes: {users: ticketWorkspace.users}}, { ...state, loaded: true, selectedId: ticketWorkspace.workspaceId })
  ),
  on(TicketWorkspacesActions.getTicketWorkspaceBoard, (state, {boardId}) => ({
    ...state,
    selectedBoardId: boardId
  })),
  on(TicketWorkspacesActions.removeUserFromTicketWorkspaceSuccess, (state, {ticketWorkspace}) => 
    ticketWorkspacesAdapter.updateOne({id: ticketWorkspace.workspaceId, changes: ticketWorkspace}, { ...state, loaded: true, selectedId: ticketWorkspace.workspaceId })
  ),
  on(TicketWorkspacesActions.updateSlackChannel, (state, {ticketWorkspace, slackChannelId, slackChannelName}) => 
    ticketWorkspacesAdapter.updateOne({id: ticketWorkspace.workspaceId, changes: {slackChannelId, slackChannelName}}, { ...state, loaded: true})
  )
);

export function ticketWorkspacesReducer(
  state: TicketWorkspacesState | undefined,
  action: Action
) {
  return reducer(state, action);
}
