<ng-container *ngrxLet="currentUser$ as currentUser">

<div class="chat-container">
  <mat-toolbar class="chat-header">
    <img class="WifejakChat__logo" src="https://cdn.razroo.com/characters/wifejak-logo.png" alt="Wifejak logo">
    <h3 class="WifejakChat__title">AI Chat</h3>

    <div class="WifejakChat__actions">
      <button mat-icon-button (click)="newChat()" matTooltip="New Chat" class="WifejakChat__plus-button" aria-label="New Chat">
        <i class="fa-solid fa-plus"></i>
      </button>
      <button mat-icon-button (click)="closeChat()" matTooltip="Close Chat" class="WifejakChat__close-button" aria-label="Close chat">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    
  </mat-toolbar>
  <div class="chat-messages">
    @if (chatMessages().length === 0) {
      <mat-card class="CodeChat__chat-block mat-card-no-padding">
        <mat-card-header>
          <ng-container>
            <img mat-card-avatar class="WifejakChat__user-avatar" 
              [ngSrc]="'https://cdn.razroo.com/characters/wifejak-logo.png'" 
              width="32" height="32" 
              alt="User profile photo"/>
            <mat-card-title class="WifejakChat__title">
              AI Assistant
            </mat-card-title>
          </ng-container>
        </mat-card-header>
        <mat-card-content class="CodeChat__chat-message">
          Hello! How can I assist you?
        </mat-card-content>
      </mat-card>
    } @else {
      @for (wifejak of chatMessages(); track wifejak) {
        <mat-card class="CodeChat__chat-block mat-card-no-padding">
          <mat-card-header>
            <ng-container>
              <img mat-card-avatar class="WifejakChat__user-avatar" [class.generating]="wifejak?.message.generating"
                [ngSrc]="wifejak?.message.role === 'assistant' ? 'https://cdn.razroo.com/characters/wifejak-logo.png' : (currentUser?.picture || 'https://razroo.b-cdn.net/characters/placeholder-avatar.png')"
                width="32" height="32" 
                alt="User profile photo"/>
              <mat-card-title class="WifejakChat__title">
                {{wifejak?.message.role === 'assistant' ? 'AI Assistant' : 'You'}}
              </mat-card-title>
            </ng-container>
          </mat-card-header>
          <mat-card-content class="CodeChat__chat-message">
            @if (wifejak?.message.role === 'assistant' && wifejak?.message.content) {
              @if(wifejak | wifejakSelector) {
                <button mat-button class="WifejakChat__quick-action-button" 
                  (click)="quickAction(wifejak)"
                  (mouseenter)="quickActionMouseEnter(wifejak)"
                  (mouseleave)="quickActionMouseLeave(wifejak)">Quick Action</button>
              }
              <p [innerHTML]="wifejak?.message.content"></p>
            } @else {
              <p [innerHTML]="wifejak?.message.content"></p>
            }
          </mat-card-content>
        </mat-card>
      }
    }
    <!-- Messages will be populated here -->
  </div>
  
  <div class="chat-input" [formGroup]="wifejakChatForm">
    <mat-form-field appearance="outline" class="wifejakChat__form-field">
          <!-- [disabled]="!(loaded$ | ngrxPush)" [readonly]="!(loaded$ | ngrxPush)" -->
      <div class="Wifejack__actions-container">
        <textarea matInput cdkTextareaAutosize rows="1" draggable="false" class="CodeChat__textarea" #textareaElement formControlName="message"
          #searchInput class="gtm-search-bar-class" matInput type="search" autofocus
            (keydown.enter)="submitChat($event, wifejakChatForm.value.message, chatMessages())"
        ></textarea>
        <div class="Wifejack__actions">
          <div class="Wifejack__actions-inner">
            <button mat-icon-button matTooltip="Go" [disabled]="!wifejakChatForm.value.message"
              (click)="submitChat($event, wifejakChatForm.value.message, chatMessages())">
              <i class="fa-solid fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
    </mat-form-field>
  </div>
</div>
</ng-container>