import { Organization } from './../../../../../data-models/src/lib/organizations/organizations.interface';
import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { User } from '@razroo-zeta/data-models';
import { map, Observable, of, take } from 'rxjs';
import { UserService } from '@razroo-zeta/data-services';

@Pipe({
    name: 'trialDaysLeft',
    standalone: false
})
export class TrialDaysLeftPipe implements PipeTransform {
  constructor(private userService: UserService) {}
  transform(organization: Organization, user: User): Observable<string | undefined> {
    if(!organization) {
      return of(undefined);
    }
    let trialStartDate: Date;
    if(user.id === organization.orgId) {
      // if personal plan, then trial period is dateCreated on user object
      trialStartDate = new Date(organization.dateCreated);
    } else if(user.id === organization.createdBy) {
      // if organization, and user that created organization is user viewing
      trialStartDate = new Date(user.dateCreatedFirstOrg as Date);
    }
    else {
      // if organization, and user viewing is not one that created organization
      return this.userService
          .getUserProfileUserId(organization.createdBy as string)
          .pipe(
            take(1),
            map((user: any) => user.dateCreatedFirstOrg)
          )
    }

    return of(this.daysLeftToTrial(trialStartDate));
  }

  daysLeftToTrial(trialStartDate: Date) {
    const daysSinceTrialBegan =  Math.ceil(
      (new Date().getTime() - trialStartDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    if (daysSinceTrialBegan < 14) {
      return (15 - daysSinceTrialBegan) + ' Days Left';
    } else if (daysSinceTrialBegan === 14) {
      return '1 Day Left';
    } else {
      return undefined;
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [TrialDaysLeftPipe],
  exports: [TrialDaysLeftPipe],
})
export class TrialDaysLeftPipeModule {}
