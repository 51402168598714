import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { WifejakAiAssistant } from '@razroo-zeta/data-graphql';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WifejakAiService {
  constructor(private apollo: Apollo) {}

  wifejakAiAssistant(userPrompt: string, pageUrl: string, messages?: Array<{role: string, content: string}>) {
    const mutation = WifejakAiAssistant;
    const variables = {
      userPrompt,
      pageUrl,
      messages
    };
    const wifejakAiAssistant$ = this.apollo.mutate({ mutation, variables });
    return from(wifejakAiAssistant$).pipe(map((response: any) => response.data?.wifejakAiAssistant));
  }
}
