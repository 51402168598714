import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TicketWorkspace } from '@razroo-zeta/data-models';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TicketWorkspaceService {

  constructor(private router: Router) {}

  navigateToBoard(workspace: TicketWorkspace) {
    const activeBoard = workspace?.activeBoard;
    if(activeBoard) {
      this.router.navigateByUrl(
        this.router.createUrlTree([
          'tickets',
          workspace.workspaceId,
          'board',
          activeBoard
        ])
      );
    } else {
      this.router.navigateByUrl(
        this.router.createUrlTree([
          'tickets',
          workspace.workspaceId,
          'backlog'
        ])
      );
    }
  }

  navigateToBacklogBoard(workspace: TicketWorkspace) {
    this.router.navigateByUrl(
      this.router.createUrlTree([
        'backlog',
        workspace.workspaceId
      ])
    );
  }

  
}
