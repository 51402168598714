
@defer (when isBrowser) {
  <adev-progress-bar></adev-progress-bar>
}
<razroo-zeta-google-analytics-gtag [trackingCode]="googleMeasurementId"></razroo-zeta-google-analytics-gtag>

<div [class]="(isDarkTheme$ | async) ? 'app-dark-theme app-container' : 'app-light-theme app-container'">
    <razroo-zeta-header
      class="header"
      (login)="login()"
      (logout)="logout()"
      (signUp)="signUp()"
      [setCurrentUser]="currentUser$ | ngrxPush"
      [isAuthenticated]="currentUser$ | ngrxPush"
      (sideNavToggle)="drawer.toggle()"
      (themeToggle)="themeToggle()"
      (wifejackAi)="wifejakAiToggle()"
      ngSkipHydration></razroo-zeta-header>
    <mat-drawer-container [hasBackdrop]="false" class="sidenav-container mat-typography">
      <mat-drawer
        #drawer
        mode="over"
        autoFocus="false"
        role="navigation"
      > 
        <mat-nav-list>
          @if(router.url.startsWith('/tickets') || router.url.startsWith('/backlog')) {
            <a
            mat-list-item
            class="App__nav-item"
            routerLinkActive="active-link"
            [routerLink]="['/tickets']"
            (click)="drawer.close()"
            >
              <i class="side-nav-text--icon fa-light fa-tickets-simple"></i>
              <span class="side-nav-text">Sprints</span>
            </a>
            <a
            mat-list-item
            class="App__nav-item"
            routerLinkActive="active-link"
            [routerLink]="['/backlog']"
            (click)="drawer.close()"
            >
              <i class="side-nav-text--icon fa-light fa-layer-group"></i>
              <span class="side-nav-text">Backlog</span>
            </a>
          } @else {
            <a
              mat-list-item
              class="App__nav-item"
              routerLinkActive="active-link"
              [routerLink]="['/finder']"
              (click)="drawer.close()"
            >
              <i class="side-nav-text--icon fa-light fa-folder-magnifying-glass"></i>
              <span class="side-nav-text">Finder</span>
            </a>
            <a
              mat-list-item
              class="App__nav-item"
              routerLinkActive="active-link"
              [routerLink]="['/home']"
              (click)="drawer.close()"
            >
              <i class="side-nav-text--icon fa-light fa-code"></i>
              <span class="side-nav-text">Workspace</span>
            </a>
            <a
            mat-list-item
            class="App__nav-item"
            routerLinkActive="active-link"
            [routerLink]="['/datasets']"
            (click)="drawer.close()"
            >
              <i class="side-nav-text--icon fa-light fa-shop"></i>
              <span class="side-nav-text">Datasets</span>
            </a>
          }
        </mat-nav-list>
      </mat-drawer>
      <mat-drawer-content role="main">
        <div class="router-outlet-div">
          <router-outlet></router-outlet>
          @if(wifejakAiShown()) {
            <razroo-zeta-wifejak-chat (chatClosed)="wifejakAiToggle()" class="App__wifejack-ai-chat"></razroo-zeta-wifejak-chat>
          }
        </div>
        <footer class="App__footer">
          <div class="App__footer-inner">
            <div class="App__footer-links">
              ©2025 Razroo LLC All Rights Reserved<span class="App__footer-separator">|</span><a routerLink="/legal/terms-of-service">Terms</a><span class="App__footer-separator">|</span>
              <a routerLink="/legal/privacy-policy">Privacy Policy</a> | <a href="https://razroo.canny.io/feature-requests" target="_blank">Feature Requests</a>
            </div>
          </div>
        </footer>
      </mat-drawer-content>
        <mat-drawer #commentsSideNav class="comments-side-nav" position="end" mode="over">
          <razroo-zeta-comments-side-nav (close)="closeCommentsSideNav()" 
          [commentsParams]="commentsParams" [orgId]="currentOrgId$ | ngrxPush"></razroo-zeta-comments-side-nav>
        </mat-drawer>
    </mat-drawer-container>
</div>
