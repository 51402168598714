import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Notification } from '@razroo-zeta/data-models';

@Pipe({
    name: 'nonReadNotifications',
    standalone: false
})
export class NonReadNotificationsPipe implements PipeTransform {
  transform(notifications: Notification[]): Notification[] {
    if(!notifications) {
      return [];
    }
    return notifications.filter(notification => notification.read !== true);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [NonReadNotificationsPipe],
  exports: [NonReadNotificationsPipe],
})
export class NonReadNotificationsPipeModule {}
