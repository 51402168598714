import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, signal } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserFacade } from '@razroo-zeta/data-access';
import { LetDirective } from '@ngrx/component';
import { NgOptimizedImage } from '@angular/common'
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WifejakAiService } from '@razroo-zeta/data-services';
import { take } from 'rxjs';
import { CodeChatMessageRoleEnum, StepWithSelector, WifejakChat, WifejakMessage } from '@razroo-zeta/data-models';
import { driver } from "driver.js";
import { WifejakSelectorPipe } from '../pipes/wifejak-selector.pipe';

@Component({
  standalone: true,
  selector: 'razroo-zeta-wifejak-chat',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatCardModule, MatInputModule, MatButtonModule, 
    MatToolbarModule, LetDirective, NgOptimizedImage, CdkTextareaAutosize, 
    MatFormFieldModule, MatDividerModule, ReactiveFormsModule, MatTooltipModule, WifejakSelectorPipe],
  templateUrl: './wifejak-chat.component.html',
  styleUrls: ['./wifejak-chat.component.scss']
})
export class WifejakChatComponent implements OnInit {
  wifejakChatForm = this.fb.group({
    message: ['', [Validators.required]]
  });
  currentUser$ = this.userFacade.currentUser$;
  chatMessages = signal<any[]>([]);
  @Output() chatClosed = new EventEmitter<void>();

  constructor(private userFacade: UserFacade, private fb: FormBuilder, private wifejakAiService: WifejakAiService) { }

  ngOnInit(): void {
  }

  closeChat(): void {
    this.chatClosed.emit();
  }

  quickAction(wifejakChat: WifejakChat): void {
    const selector = wifejakChat.stepsWithSelectors.find(step => step.selector)?.selector;
    const generateTicketsButton = document.querySelector(selector as string) as HTMLElement;
    generateTicketsButton.click();
  }

  quickActionMouseEnter(wifejakChat: WifejakChat) {
    const selector = wifejakChat.stepsWithSelectors.find(step => step.selector)?.selector;
    const generateTicketsButton = document.querySelector(selector as string) as HTMLElement;
    generateTicketsButton.classList.add('wifejak-animation');
  }

  quickActionMouseLeave(wifejakChat: WifejakChat) {
    const selector = wifejakChat.stepsWithSelectors.find(step => step.selector)?.selector;
    const generateTicketsButton = document.querySelector(selector as string) as HTMLElement;
    generateTicketsButton.classList.remove('wifejak-animation');
  }

  submitChat(event: any, userMessage: string, messages: any[]): void {
    // stop enter from going to next line
    event.preventDefault();
    const userMessageChat = {message:{role: CodeChatMessageRoleEnum.User, content: userMessage}};
    const assistantChat = {message:{role: CodeChatMessageRoleEnum.Assistant, generating: true}};

    this.chatMessages.set([...messages, userMessageChat, assistantChat]);
    const prunedMessages = messages.map(message => ({
      role: message.message.role,
      content: message.message.content
    }));
    this.wifejakAiService.wifejakAiAssistant(userMessage, 'https://projects.razroo.com/tickets/zeta/board/110', prunedMessages)
      .pipe(take(1)).subscribe((wifejakChat: WifejakChat) => {
        const latestMessage = wifejakChat.message;
        const latestWifejakChat = {message: latestMessage, stepsWithSelectors: wifejakChat.stepsWithSelectors};
        const updatedMessages = [...this.chatMessages().slice(0, -1), latestWifejakChat];
        this.chatMessages.set(updatedMessages);
        if(wifejakChat.questionOrCommand === 'command') {
          this.actOutCommand(wifejakChat.stepsWithSelectors, wifejakChat.textToType);
        }
    });
    this.wifejakChatForm.controls['message'].reset();
  }

  dispatchAngularEvents(input: any, text: string, i: number) {
    input.dispatchEvent(new Event('input', { bubbles: true }));
    input.dispatchEvent(new Event('change', { bubbles: true }));
    // Force Angular to detect changes on the input
    input.dispatchEvent(new KeyboardEvent('keydown', { key: text[i] }));
    input.dispatchEvent(new KeyboardEvent('keypress', { key: text[i] }));
    input.dispatchEvent(new KeyboardEvent('keyup', { key: text[i] }));
  }

  async actOutCommand(stepsWithSelectors: StepWithSelector[], textToType: string) {
    for (const step of stepsWithSelectors) {
      const selector = step.selector;
      // Wait for element to be visible before proceeding
      const commandElement = await this.waitForElement(selector as string);

      if(step.inputType === 'text') {
        // only require focus if input
        commandElement.focus();
        commandElement.click();
        const input = commandElement as HTMLInputElement;
        const text = textToType;
        input.value = '';
        for(let i = 0; i < text.length; i++) {
          await new Promise(resolve => setTimeout(resolve, 50));
          input.value += text[i];
          this.dispatchAngularEvents(input, text, i);
        }
      } else {
        commandElement.click();
      }
    }
  }

  private waitForElement(selector: string): Promise<HTMLElement> {
    return new Promise((resolve) => {
      const checkElement = () => {
        const element = document.querySelector(selector) as HTMLElement;
        if (element && this.isElementVisible(element)) {
          // Add a small delay to ensure element is fully rendered and interactive
          setTimeout(() => resolve(element), 100);
          return;
        }
        setTimeout(checkElement, 100); // Use setTimeout instead of requestAnimationFrame
      };
      checkElement();
    });
  }

  private isElementVisible(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
      window.getComputedStyle(element).visibility !== 'hidden' &&
      window.getComputedStyle(element).display !== 'none'
    );
  }

  newChat() {
    this.wifejakChatForm.controls['message'].reset();
    this.chatMessages.set([]);
  }
}
