import { CommonModule } from '@angular/common';
import { Component, OnInit, NgModule, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'razroo-zeta-social-media-share',
    templateUrl: './social-media-share.component.html',
    styleUrls: ['./social-media-share.component.scss'],
    standalone: false
})
export class SocialMediaShareComponent implements OnInit {
  @Input() template: any; 
  @Input() currentUserId: any;
  @Input() comment: any;
  currentUrl = window.location.href;

  constructor() {}

  ngOnInit(): void {
  }
  
  shareToTwitter(comment: string, currentUserId: string) {
    let tweetText: string;
  
    if (this.comment.userId === currentUserId) {
      tweetText = 'I commented "' + comment + '" on ' + this.currentUrl;
    } else {
      tweetText = comment  + ' ' + this.currentUrl;
    }
  
    const tweetUrl = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(tweetText);
    window.open(tweetUrl);
  }
  


  shareToReddit(comment: string){
    const shareUrl = 'https://reddit.com/submit?title=' + comment + '&url='+this.currentUrl
    window.open(shareUrl)
  }
  // shareToLinkedIn(comment: string) {
  //   const shareText = 'I commented "' + comment + '" on ' + this.currentUrl;
  //   const encodedComment = encodeURIComponent(shareText);
  
  //   const shareUrl = `https://www.linkedin.com/sharing/share-post?mini=true&url=${this.currentUrl}&summary=${encodedComment}`;
  //   window.open(shareUrl);
  // }  
  
  
}

@NgModule({
  imports: [CommonModule,  MatButtonModule],
  declarations: [SocialMediaShareComponent],
  exports: [SocialMediaShareComponent],
})
export class SocialMediaShareComponentModule {
}
