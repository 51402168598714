import { Component, OnInit, ViewChild, Inject, PLATFORM_ID, Renderer2, OnDestroy, inject, ChangeDetectionStrategy, signal } from '@angular/core';
import { WindowRefService } from '../../../../libs/common-services/src/lib/window-ref/window-ref.service';
import { ThemeService } from '../../../../libs/ui-services/src/theme.service';
import { LocalStorageService } from '../../../../libs/common-services/src/lib/local-storage/local-storage.service';
import { environment } from '@razroo-zeta/common-environment';
import { AuthService } from '../../../../libs/data-services/src/lib/auth/auth.service';
import { UserFacade } from '../../../../libs/data-access/src/lib/+state/user/user.facade';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CommentsToggleService } from '../../../../libs/ui/common/src/lib/comments-toggle/comments-toggle.service';
import { MatSidenav } from '@angular/material/sidenav';
import {BehaviorSubject, catchError, EMPTY, filter, Subject, take, takeUntil} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { LocalForageService } from '../../../../libs/common-services/src/lib/local-forage/local-forage.service';
import { MatDialog } from '@angular/material/dialog';
import { CookiesService } from '@razroo-zeta/data-services';

declare let gtag: Function;

@Component({
    selector: 'razroo-zeta-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  static isBrowser = new BehaviorSubject<boolean>(false);
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  isDarkMode: any;
  isDarkTheme$: any;
  events: string[] = [];
  title = 'zeta-frontend';
  unsubscribe$ = new Subject();
  wifejakAiShown = signal(false);
  flightCount: number;
  currentUser$: any;
  currentOrgId$: any;
  programmingLanguage = '';
  googleMeasurementId = environment.googleMeasurementId;

  @ViewChild('commentsSideNav') sideNav: MatSidenav;

  themeToggle() {
    this.localForageService.getItem('isDarkMode').then((isDarkMode: boolean) => {
      const newDarkModeState = isDarkMode ? false : true;
      this.themeService.isDarkTheme$.next(newDarkModeState);
      const overlayContainerClasses =
        this.overlayContainer.getContainerElement().classList;
      if (Array.from(overlayContainerClasses).includes('app-dark-theme')) {
        overlayContainerClasses.remove('app-dark-theme');
        overlayContainerClasses.add('app-light-theme');
        this.renderer.removeClass(this.windowRefService.nativeWindow.document.body, 'app-dark-theme');
        this.renderer.addClass(this.windowRefService.nativeWindow.document.body, 'app-light-theme');
      } else {
        overlayContainerClasses.add('app-dark-theme');
        overlayContainerClasses.remove('app-light-theme');
        this.renderer.addClass(this.windowRefService.nativeWindow.document.body, 'app-dark-theme');
        this.renderer.removeClass(this.windowRefService.nativeWindow.document.body, 'app-light-theme');
      }
      this.localForageService.setItem('isDarkMode', newDarkModeState);
    });
  }

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private userFacade: UserFacade,
    private overlayContainer: OverlayContainer,
    private commentsToggleService: CommentsToggleService,
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRefService: WindowRefService,
    private localStorageService: LocalStorageService,
    private localForageService: LocalForageService,
    public router: Router,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private cookiesService: CookiesService
  ) {
    this.router.events.subscribe(event => {
      if(this.isBrowser && event instanceof NavigationEnd) {
        gtag('config', environment.googleMeasurementId, {
          'page_title': event.urlAfterRedirects,
          'page_path': event.urlAfterRedirects
        });
      }
    });
    AppComponent.isBrowser.next(this.isBrowser);
    if (this.localStorageService.getItem('redirect_url')) {
      if (this.windowRefService.nativeWindow.location.href !== this.localStorageService.getItem('redirect_url')) {
        this.windowRefService.nativeWindow.location.replace(this.localStorageService.getItem('redirect_url')!);
      } else {
        this.localStorageService.removeItem('redirect_url');
      }
    }
  }

  closeCommentsSideNav(){
    this.commentsToggleService.closeSideNav()
  }
  commentsParams:any;
  ngOnInit() {
    this.isDarkMode = this.localForageService && this.localForageService.getItem('isDarkMode');
    this.isDarkTheme$ = this.themeService && this.themeService.isDarkTheme$;
    this.currentUser$ = this.userFacade && this.userFacade.currentUser$;
    this.currentOrgId$ = this.userFacade && this.userFacade.currentOrgId$;

    this.localForageService.getItem('isDarkMode').then((isDarkMode: boolean) => {
      this.themeService.isDarkTheme$.next(isDarkMode);

      const overlayContainerClasses =
        this.overlayContainer.getContainerElement().classList;
      if (!isDarkMode) {
        overlayContainerClasses.remove('app-dark-theme');
        overlayContainerClasses.add('app-light-theme');
        this.renderer.removeClass(this.windowRefService.nativeWindow.document.body, 'app-dark-theme');
        this.renderer.addClass(this.windowRefService.nativeWindow.document.body, 'app-light-theme');
      } else {
        overlayContainerClasses.add('app-dark-theme');
        overlayContainerClasses.remove('app-light-theme');
        this.renderer.addClass(this.windowRefService.nativeWindow.document.body, 'app-dark-theme');
        this.renderer.removeClass(this.windowRefService.nativeWindow.document.body, 'app-light-theme');
      }
    });

    this.commentsToggleService.sideNavToggle.pipe(takeUntil(this.unsubscribe$), catchError(() => EMPTY)).subscribe(res => {
        if(res){
          this.commentsParams = res;
        }
        this.sideNav.toggle()
    })
    this.overlayContainer.getContainerElement().classList.add('app-dark-theme');
  }

  wifejakAiToggle() {
    this.wifejakAiShown.set(!this.wifejakAiShown());
  }

  login() {
    this.authService.loginWithRedirect();
  }

  toggleSideNav(sidenav: MatSidenav) {
    console.log('toggleSideNav called');
    sidenav.toggle();
  }

  logout() {
    this.localStorageService.removeItem('socketVsCode');
    this.authService.logout(environment.codeHostUrl);
  }

  signUp() {
    this.authService.signUpWithRedirect();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
