import { kebabCase } from 'lodash';
import { GithubSubscriptionService } from '../../../../../data-services/src/lib/github/github-subscription/github-subscription.service';
import { FileTreeService, FlatFolderFile, LocalForageService } from '@razroo-zeta/common-services';
import { VscodeService } from './../../../../../data-services/src/lib/vscode/vscode.service';
import { TemplatesFacade } from './../../../../../data-access/src/lib/+state/templates/templates.facade';
import { VscodeFacade } from './../../../../../data-access/vscode/src/lib/facade/vscode.facade';
import { ColorService } from './../../../services/color/color.service';
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EMPTY, Subject, catchError, first, take, takeUntil } from 'rxjs';
import { ActiveProject, GithubRepo,  OrganizationUser,  PackageJsonParams, Template, TemplateInputParameter, VsCodeInstance } from '@razroo-zeta/data-models';
import { GenerateCodeService, GithubService, PathService, TemplateService } from '@razroo-zeta/data-services';
import { GenerateCodeFacade, OrganizationsFacade } from '@razroo-zeta/data-access/organizations';
import { environment } from '@razroo-zeta/common-environment';
import { UserFacade } from '@razroo-zeta/data-access';
import { Router } from '@angular/router';
import { LatestStep } from 'libs/data-models/src/lib/user/user.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Path } from '@razroo-zeta/data-models';

@Component({
    selector: 'razroo-zeta-generate-code-dialog',
    templateUrl: './generate-code-dialog.component.html',
    styleUrls: ['./generate-code-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GenerateCodeDialogComponent implements OnInit, OnDestroy {
  @ViewChildren('myCheckbox') private myCheckboxes: QueryList<any>;
  @ViewChild('defaultFilePath') defaultFilePathInput: ElementRef;
  formControlsObj = {} as any;
  fileParamsFormGroup: UntypedFormGroup = this.fb.group(this.formControlsObj);
  selectedTemplate$ = this.templatesFacade.selectedTemplate$
  templateLoaded$ = this.templatesFacade.loaded$;
  commandText$ = this.templatesFacade.commandText$;
  selectedStepper$ = this.templatesFacade.selectedStepper$;
  templatesRecipeIdHoveredOver$ = this.templatesFacade.getTemplatesRecipeIdHoveredOver$;
  vsCodeInstance$ = this.vscodeFacade.vsCodeInstance$;
  vscodeLoaded$ = this.vscodeFacade.loaded$;
  vsCodeInstances;
  noVSCodeConnected = false;
  generateMode: 'Github' | 'VSCode' = 'Github';
  githubRepo: GithubRepo;
  githubRepos: GithubRepo[];
  githubBranch: string;
  githubRepoName: string;
  githubFileTree: any;
  vsCodeParameters$ = this.vscodeFacade.vsCodeParameters$;
  vsCodeFilesToGenerate$ = this.vscodeFacade.vsCodeFilesToGenerate$;
  vsCodeDirectories$ = this.vscodeFacade.vsCodeDirectories$;
  user$ = this.userFacade.currentUser$;
  template: Template;
  panelOpenState = {};
  folders: any = [];
  checkedCheckbox = '';
  selectedVSCodeInstance;
  privateDirectoriesString: string;
  packageJson?: PackageJsonParams;
  parameters: any = [];
  defaultFilePath: string;
  dataSource: any;
  dataSourceWithDefaultPath: any;
  defaultMode?: boolean = undefined;
  pathInputEvent$ = new Subject();
  unsubscribe$: Subject<string> = new Subject();
  treesObj = {};
  filesToGenerateObj = {};
  directoriesWorker: Worker;
  projectSchemaValues:any;
  isSchema: boolean = false;
  selectedSchemaValue: any;
  githubBranches: any;
  branchControl = new FormControl('');
  options: string[] = [];
  panelCloser: boolean = false;
  pathForStarterDropdown = {id: 'angular',orgId: 'community',title: 'Angular'};
  pathsLoading;
  pathsDropdownItems;
  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {
      template: Template,
      user: OrganizationUser,
      userId: string,
      userOrgId: string,
      latestStep: LatestStep | undefined,
      githubOrg: string,
      githubRepos: GithubRepo[],
      activeProject: ActiveProject
    },
    private dialogRef: MatDialogRef<GenerateCodeDialogComponent>,
    private vscodeService: VscodeService,
    private vscodeFacade: VscodeFacade,
    private colorService: ColorService,
    private localForageService: LocalForageService,
    private templatesFacade: TemplatesFacade,
    private generateCodeService: GenerateCodeService,
    private generateCodeFacade: GenerateCodeFacade,
    private githubSubscriptionService: GithubSubscriptionService,
    private organizationsFacade: OrganizationsFacade,
    private snackbar: MatSnackBar,
    private userFacade: UserFacade,
    private router: Router,
    private githubService: GithubService,
    private fileTreeService: FileTreeService,
    private pathService: PathService,
    private templateService: TemplateService
  ) { }

  async ngOnInit(): Promise<void> {
    this.githubRepos = this.data.githubRepos ? [...this.data.githubRepos] : [];
    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe(user => {
      this.githubRepos = user?.githubRepos as GithubRepo[];
    })

    await this.createParameters(true, this.data.template);
    this.vscodeFacade.loadParameters(this.replaceParamsWithLatestStep());
    this.vscodeFacade.vsCodeParameters$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(parameters => {
        const formifiedParameters = {};
        if(!parameters) {
          return;
        }
        for(const parameter of parameters) {
          formifiedParameters[parameter.name] = parameter.defaultValue;
        }
        this.fileParamsFormGroup.patchValue(formifiedParameters);
        this.parameters = parameters;
        // this.replaceParamsWithLatestStep();
    });

    
    // schema logic
    const nameParam = Array.isArray(this.data.template?.parameters) ? this.data.template?.parameters.find(param => param.name === 'name') : undefined;
    if(Array.isArray(nameParam?.optionalTypes) ? nameParam?.optionalTypes.find(type => (type.name === 'schema' && type.selected === true)) : false){
      this.isSchema = true;
      const schemaPathId = this.data.activeProject ? this.data.activeProject.id : this.data.template.pathId;
      this.localForageService.getItem(`${schemaPathId}-schema`).then(localSchema => {
        if(!localSchema) {
          return;
        }
        this.projectSchemaValues = JSON.parse(localSchema);
      })
    }

    this.initGithubView();
  }

  initGithubView() {
    this.selectActiveGithubRepo()  
  }

  selectActiveGithubRepo() {
    const activeProject = this.data.activeProject;
    const githubRepos = this.data.githubRepos;
    if(!this.data.activeProject || !this.data.githubRepos ) {
      return;
    } else {
      const coreProjectPathId = kebabCase(activeProject.title);
      const activeGithubRepo = githubRepos.find(githubRepo => githubRepo.name === coreProjectPathId);
      if(activeGithubRepo) {
        this.githubRepo = activeGithubRepo;
        const recipeId = this.data.template.recipeId;
        this.branchControl.setValue(recipeId);
        this.vscodeFacade.updateParameterValue('projectName', activeGithubRepo.name);
      }
    }
  }

  replaceParamsWithLatestStep(){
    let updatedParams = this.data.template.parameters;
    // latestStep now present first time around so putting in logic in case not there
    const latestStepParameters = this.data.latestStep?.parameters;
    if(this.data.latestStep?.recipeId === this.data.template.recipeId && latestStepParameters){
      const latestStepParams = JSON.parse(latestStepParameters);
      for(const key of Object.keys(latestStepParams)){
        if(this.fileParamsFormGroup.controls?.[key]){
          const relevantParam = this.parameters.find(param => param.name === key);
          updatedParams = this.parameters.map(obj => {
            if (obj === relevantParam) {
              // this.vscodeFacade.updateParameterValue(key, latestStepParams[key])
              return { ...obj, defaultValue: latestStepParams[key]};
            }
            return obj;
          });
          this.parameters = updatedParams;
        } 
      }
      let paramsObj = {};
      updatedParams && updatedParams.forEach(param => {
        paramsObj[param.name] = param.defaultValue;
      })
      this.fileParamsFormGroup.patchValue(paramsObj);
      console.log('fileParamsFormGroup value: ', this.fileParamsFormGroup.value)
    }
    return updatedParams;
  }

  trackInput(index : number) {
    return index;
  };

  async selectVSCodeInstance(vsCodeInstance: VsCodeInstance, template: Template) {
    if(!vsCodeInstance || this.generateMode === 'Github') {
      return;
    }
    this.packageJson = vsCodeInstance.packageJsonParams;
    this.selectedVSCodeInstance = vsCodeInstance;

    const projectName = vsCodeInstance.packageJsonParams && vsCodeInstance.packageJsonParams.name;
    const vsCodeInstanceId = vsCodeInstance.vsCodeInstanceId;
    this.vscodeFacade.loadVscodeInstance(vsCodeInstanceId, template, this.data.userId);
    this.formControlsObj['projectName'] = projectName;
    this.vscodeFacade.updateParameterValue('projectName', projectName);
  }

  logFolderClick(event: {folder: FlatFolderFile}) {
    this.vscodeFacade.toggleVsCodeFolder(event.folder);
  }

  getGithubRepos(githubOrg?: string) {
    const {userId, userOrgId } = this.data;
    this.generateCodeService.getGithubRepos(userId, userOrgId, githubOrg as string);
  }

  getGithubBranches() {
    const {userId, userOrgId } = this.data;
    this.githubSubscriptionService.sendGithubBranchesDataSub(userId).pipe(first()).subscribe((data: {userId: string, githubBranches: any} | any) => {
      this.githubBranches = data.githubBranches;
    });
    const githubPortalUrl = environment.githubPortalUrl;
    const githubRepoName = this.githubRepo.name;
    const githubRepoOwner = this.githubRepo.owner && this.githubRepo.owner.login;
    const url = `${githubPortalUrl}/api/auth/login?returnTo=/branches/${userId}_${githubRepoName}_${githubRepoOwner}`;
    const width = 800;
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    window.open(url, "myWindow", `width=${width},height=${height},left=${left},top=${top}`);
  }

  async createParameters(initial = false, template: Template) {
    if(template.parameters && template.parameters.length ) {
      if(initial) {
        this.parameters = template.parameters;
      } else {
        let newParams:any = [];
        Object.keys(this.fileParamsFormGroup.value).forEach(paramName => {
          if(paramName !== 'selector'){
            let param = template.parameters && template.parameters.find(param => param.name === paramName);
            newParams.push({...param, defaultValue: this.fileParamsFormGroup.value[paramName], originalValue: param!.defaultValue})
          }
        })
        this.parameters = newParams;
      }
      Array.isArray(this.parameters) && this.parameters.forEach((param: TemplateInputParameter) => {
        this.formControlsObj[param.name] = [param.defaultValue, Validators.required];
      });
      const newFormControlsObj = {
        ...this.formControlsObj,
        selector: this.packageJson && this.packageJson.name,
      };
      this.fileParamsFormGroup = this.fb.group(newFormControlsObj);
    }
  }

  getProjectName(packageJson: PackageJsonParams | undefined): string | undefined {
    return packageJson && packageJson.name;
  }

  nameParameterValue = '';
  inputChanged($event, parameter, parameters: any, template: Template) {
    if (template.type !== "Command") {
      const newInputValue = $event.target.value;
      this.vscodeFacade.updateVscodeInstanceParameter(parameter, newInputValue);
      const updatedParameters = parameters.map(param => param.name === parameter.name ? {...parameter, defaultValue: newInputValue} : param);
      const filesToGenerateFlatFolderTree =  this.vscodeService.createFilesToGenerateFlatFolderFileTree(template.fileTree, updatedParameters);
      this.vscodeFacade.loadFilesToGenerateFolderFileTree(filesToGenerateFlatFolderTree);
    }
    if(parameter.name === 'name'){
      this.nameParameterValue =  this.fileParamsFormGroup.value.name;
    }
  }

  async switchTab(event, template: Template){
    this.generateMode = event.value;
    this.vscodeFacade.directoriesLoading();
    if(event.value === 'Github') {
      this.initGithubView();
      this.closeTreePanel();
    }
  }

  closeTreePanel(){
    this.panelCloser = !this.panelCloser;
  }

  loadGithubTree(){
    this.vscodeFacade.directoriesLoading();
    if(this.githubRepo.name && this.branchControl.value){
      this.githubService.githubFileTree(this.data.githubOrg, this.githubRepo.name, this.branchControl.value).pipe(first()).subscribe((res:any) => {
        const githubFileTree = this.fileTreeService.returnFlatFolderStructure(JSON.parse(res.content as string), undefined).filter(file => file.expandable);
        this.vscodeFacade.loadVscodeFolderTreeSuccess(githubFileTree);
      })
    }
  }

  filterGithubRepos($event) {
    const githubRepoName = $event.target.value;
    this.githubRepoName = $event.target.value;
    this.githubRepos = this.data.githubRepos.filter(githubRepo => {
      if (githubRepo.name) {
        return githubRepo.name.toLowerCase().includes(githubRepoName.toLowerCase());
      }
      return []; // Handle the case where name is null or undefined
    });
  }

  treeOpened(){
    const stepFileTree = this.data.template.fileTree;
    const stepFlatFolderTree = this.vscodeService.createFilesToGenerateFlatFolderFileTree(stepFileTree, this.parameters);
    this.vscodeFacade.loadFilesToGenerateFolderFileTree(stepFlatFolderTree);

    if(this.generateMode === 'Github' && this.githubRepo && this.branchControl.value) {
      this.loadGithubTree();
    }
  }

  filePathInputChanged(event: {newInputValue: string, open: boolean}, parameter: TemplateInputParameter, parameters: any, vscodeDirectories: string, fileTree: string[]) {
    const updatedParameters = parameters.map(param => param.name === parameter.name ? {...parameter, defaultValue: event.newInputValue} : param);
    const filesToGenerateFlatFolderTree =  this.vscodeService.createFilesToGenerateFlatFolderFileTree(fileTree, updatedParameters);
    this.vscodeFacade.loadFilesToGenerateFolderFileTree(filesToGenerateFlatFolderTree);
    this.vscodeFacade.updateVscodeInstanceParameter(parameter, event.newInputValue);
  }

  openExpansionPanel(index: number, parameter: any) {
    this.panelOpenState[index] = {open: true, param: parameter};
  }

  closeExpansionPanel(index: number) {
    this.panelOpenState[index].open = false;
  }

  transformColorParams() {
    const currentFormGroup = this.fileParamsFormGroup.value;
    this.parameters && this.parameters.map(param => {
      if (param.type === "color" && currentFormGroup[param.name]) {
        const colorPallette = this.colorService.getPalette(currentFormGroup[param.name]);
        const materialColors = {
          [`${param.name}-lighter`]: colorPallette['100'],
          [`${param.name}`]: colorPallette['500'],
          [`${param.name}-darker`]: colorPallette['800'],
        }
        this.fileParamsFormGroup = this.fb.group({
          ...this.fileParamsFormGroup.value,
          ...materialColors
        });
      }
    });
  }

  generate(generateInput: any, template: Template, user: OrganizationUser) {
    const branch = this.branchControl.value?.trim();
    if(!template.starter) {
      if (!branch || ['main', 'master', 'develop'].includes(branch)) {
        this.snackbar.open('Cannot generate to default branch', '', { duration: 3000});
        return
      }
    }
    
    //if no github repo or vsCodeInstance don't close dialog or generate unless creating starter
    if(!template.starter && !this.githubRepo && !this.githubRepoName && !this.selectedVSCodeInstance) {
      return
    }
    
    const githubRepoAlt = this.githubRepoName ? {
      name: this.githubRepoName
    } : undefined;

    const result = {
      ...generateInput,
      template: template,
      params: this.selectedSchemaValue ? {...this.fileParamsFormGroup.value, nameSchema: this.selectedSchemaValue} : this.fileParamsFormGroup.value,
      projectParams: {
        pathId: this.githubRepo ? this.githubRepo.name : this.selectedVSCodeInstance
      },
      vsCodeInstanceId: this.selectedVSCodeInstance && this.selectedVSCodeInstance.vsCodeInstanceId,
      vsCodeInstances: this.vsCodeInstances,
      generateMode: this.generateMode,
      githubRepo: this.githubRepo ? this.githubRepo : githubRepoAlt,
      githubBranch: branch,
      orgType: user.orgType
    }

    if(this.data.activeProject){
      result.projectParams = {
        pathId: this.data.activeProject.id,
        orgId: this.data.activeProject.orgId
      }
    }
    // putting here for now. Soon will be in it's own input component
    this.transformColorParams();
    if(template.batchId) {
      const stepper = template?.stepper;
      const activeIndex = stepper && stepper.findIndex(step => step.id === template.id);
      const nextTemplateIndex = activeIndex + 1;
      if(nextTemplateIndex > stepper.length - 1) {
        this.dialogRef.close(result)
      } else {
        const generateCodeParameters = this.generateCodeService.createGenerateCodeParameters(result);
        console.log('generateCodeParameters: ', generateCodeParameters);
        this.generateCodeFacade.generateCode(generateCodeParameters, template.stepper, template, this.vsCodeInstances);
      }
    } else {
      this.dialogRef.close(result)
    }
  }

  setSchemaValue(schemaValuesJson){
    this.selectedSchemaValue = schemaValuesJson;
  }

  changeDefaultMode($event) {
    this.defaultMode = $event.checked;
  }

  connectGithub(){
    this.dialogRef.close();
    this.router.navigateByUrl('/settings/integrations?show-github=true');
  }
  
  selectGithubRepo(repo) {
    this.closeTreePanel();
    if(repo){
      this.githubRepo = repo;
      // this.vscodeFacade.loadParameters(this.replaceParamsWithLatestStep());
      if(this.fileParamsFormGroup.value?.projectName){
        this.formControlsObj['projectName'] = repo.name;
        this.vscodeFacade.updateParameterValue('projectName', repo.name);
      }
    }
  }
  
  getPaths(){
    this.pathsLoading = true;
    this.pathService.getPathsForDropdown(this.data.userOrgId, 'All', false).pipe(take(1), catchError(() => EMPTY)).subscribe((res: any) => {
      this.pathsDropdownItems = res;
      this.pathsLoading = false;
    })
  }
  setPathHandle($event: {path: Path}) {
    if ($event.path) {
      this.templateService.getPathTemplate($event.path.orgId, $event.path.id).pipe(take(1), catchError(() => EMPTY)).subscribe((pathTemplate: any) => {
        this.pathForStarterDropdown = pathTemplate;
        this.templateService.getRecipe(pathTemplate.orgId, pathTemplate.id, pathTemplate.stepper[0].id, true, true).pipe(take(1), catchError(() => EMPTY)).subscribe((recipe:any) => {
          this.templatesFacade.loadTemplate(recipe.orgId, recipe.pathId, recipe.id, (recipe.stepper?.[0] ? recipe.stepper[0].id : undefined));
        })
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next('true');
    this.unsubscribe$.complete();
  }
}
