import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { take } from 'rxjs';
import { OrganizationUser } from '@razroo-zeta/data-models';
import { CreateSessionService, OrganizationsService, PricesService, ProductsService } from '@razroo-zeta/data-services';
import { WindowRefService } from '@razroo-zeta/common-services';

@Component({
    selector: 'razroo-zeta-unpaid-ticket-dialog',
    templateUrl: './unpaid-ticket-dialog.component.html',
    styleUrls: ['./unpaid-ticket-dialog.component.scss'],
    standalone: false
})
export class UnpaidTicketDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      user: OrganizationUser
    },
    private dialogRef: MatDialogRef<UnpaidTicketDialogComponent>,
    private createSessionService: CreateSessionService,
    private windowRefService: WindowRefService,
    private organizationsService: OrganizationsService,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {  }
  
  upgrade($event: any, user: OrganizationUser) {
    this.productsService.getStripeProduct('projects').pipe(take(1)).subscribe((product :any) => {
      const price = product.monthly.priceId;
      const productId = product.id;
      this.createSessionService
        .createStripeCheckoutSession(price, user?.orgId, 1, productId, user?.stripeCustomerId)
        .pipe(take(1))
        .subscribe((res:any) => {
          this.windowRefService.nativeWindow.location.href = res['url'];
        });
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  declarations: [UnpaidTicketDialogComponent],
  exports: [UnpaidTicketDialogComponent],
})
export class UnpaidTicketComponentModule {}
