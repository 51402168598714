import { gql } from '@apollo/client/core';

export const WifejakAiAssistant = gql`
  mutation WifejakAiAssistant(
    $userPrompt: String!
    $pageUrl: String!
    $messages: [ChatCompletionRequestMessageInput]
  ) {
    wifejakAiAssistant(
      userPrompt: $userPrompt
      pageUrl: $pageUrl
      messages: $messages
    ) {
      message {
        role
        content
        name
      }
      textToType
      stepsWithSelectors {
        title
        selector
        inputType
        textToType
      }
      questionOrCommand
      url
    }
  }
`;
