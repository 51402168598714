import { Injectable, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as TicketWorkspacesActions from './ticket-workspaces.actions';
import * as TicketWorkspacesSelectors from './ticket-workspaces.selectors';
import { Ticket, TicketWorkspace, TicketWorkspaceBoard, TicketWorkspaceBoardUpdateParams, TicketWorkspaceUpdateParams } from '@razroo-zeta/data-models';

@Injectable()
export class TicketWorkspacesFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(TicketWorkspacesSelectors.selectTicketWorkspacesLoaded));
  boardsLoaded$ = this.store.pipe(select(TicketWorkspacesSelectors.selectTicketWorkspaceBoardsLoaded));
  selectTicketWorkspaceBoardId$ = this.store.pipe(select(TicketWorkspacesSelectors.selectTicketWorkspaceBoardId));
  selectTicketWorkspaceBoard$ = this.store.pipe(select(TicketWorkspacesSelectors.selectTicketWorkspaceBoard));
  allTicketWorkspaces$ = this.store.pipe(select(TicketWorkspacesSelectors.selectAllTicketWorkspaces));
  selectedTicketWorkspace$ = this.store.pipe(select(TicketWorkspacesSelectors.selectEntity));
  ticketWorkspaces$ = this.store.pipe(select(TicketWorkspacesSelectors.selectTicketWorkspaces)); 
  ticketWorkspaceBoards$ = this.store.pipe(select(TicketWorkspacesSelectors.selectTicketWorkspaceBoards)); 

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  loadTicketWorkspaces(orgId: string) {
    this.store.dispatch(TicketWorkspacesActions.loadTicketWorkspaces({orgId}));
  }
  
  loadTicketWorkspace(userId: string, orgId: string, workspaceId: string) {
    this.store.dispatch(TicketWorkspacesActions.loadTicketWorkspace({userId, orgId, workspaceId}));
  }

  loadTicketWorkspaceSuccess(userId: string, ticketWorkspace: TicketWorkspace) {
    this.store.dispatch(TicketWorkspacesActions.loadTicketWorkspaceSuccess({ userId, ticketWorkspace }));
  }

  createTicketWorkspace(orgId: string, workspaceName: string, jiraProject: string, sprintPage: boolean, description?: string) {
    this.store.dispatch(TicketWorkspacesActions.createTicketWorkspace({ orgId, workspaceName, jiraProject, sprintPage, description }));
  }

  deleteTicketWorkspace(orgId: string, workspaceId: string){
    this.store.dispatch(TicketWorkspacesActions.deleteTicketWorkspace({orgId, workspaceId}))

  }

  createTicketWorkspaceBoard(orgId: string, workspaceId: string, boardName: string, startDate: any, endDate: any, description?: string){
    this.store.dispatch(TicketWorkspacesActions.createTicketWorkspaceBoard({orgId, workspaceId, boardName, startDate, endDate, description}))
  }

  loadTicketWorkspaceSprintBoards(orgId: string, workspaceId: string, includeBacklog?: boolean){
    this.store.dispatch(TicketWorkspacesActions.loadTicketWorkspaceSprintBoards({orgId, workspaceId, includeBacklog}))
  }

  loadTicketWorkspaceSprintBoardsSuccess(orgId: string, workspaceId: string, boards: TicketWorkspaceBoard[], tickets: Ticket[]){
    this.store.dispatch(TicketWorkspacesActions.loadTicketWorkspaceSprintBoardsSuccess({orgId, workspaceId, boards, tickets}))
  }

  updateTicketWorkspaceBoard(orgId: string, workspaceId: string, boardId: number, boardUpdateParams: TicketWorkspaceBoardUpdateParams){
    this.store.dispatch(TicketWorkspacesActions.updateTicketWorkspaceBoard({orgId, workspaceId, boardId, boardUpdateParams}))
  }

  deleteTicketWorkspaceBoard(orgId: string, workspaceId: string, boardId: number, newBoardId?: number){
    this.store.dispatch(TicketWorkspacesActions.deleteTicketWorkspaceBoard({orgId, workspaceId, boardId, newBoardId}))
  }

  setActiveTicketWorkspaceBoard(boardId: number | undefined){
    this.store.dispatch(TicketWorkspacesActions.setActiveTicketWorkspaceBoard({boardId}))
  }

  updateTicketWorkspace(orgId: string, workspaceId: string, ticketWorkspaceUpdateParams: TicketWorkspaceUpdateParams){
    this.store.dispatch(TicketWorkspacesActions.updateTicketWorkspace({orgId, workspaceId, ticketWorkspaceUpdateParams}))
  }

  updateSlackChannel(ticketWorkspace: TicketWorkspace, slackChannelId: string, slackChannelName: string){
    this.store.dispatch(TicketWorkspacesActions.updateSlackChannel({ticketWorkspace, slackChannelId, slackChannelName}));
  }

  startSprint(orgId: string, workspaceId: string, boardId: number){
    this.store.dispatch(TicketWorkspacesActions.startSprint({orgId, workspaceId, boardId}))
  }

  getTicketWorkspaceBoard(orgId: string, workspaceId: string, boardId: number){
    this.store.dispatch(TicketWorkspacesActions.getTicketWorkspaceBoard({orgId, workspaceId, boardId}))
  }

  addUserToTicketWorkspace(orgId: string, workspaceId: string, newUserId: string){
    this.store.dispatch(TicketWorkspacesActions.addUserToTicketWorkspace({orgId, workspaceId, newUserId}))
  }

  removeUserFromTicketWorkspace(orgId: string, workspaceId: string, userId: string){
    this.store.dispatch(TicketWorkspacesActions.removeUserFromTicketWorkspace({orgId, workspaceId, userId}))
  }

  getCompletedWorkspaceBoards(orgId: string, workspaceId: string){
    this.store.dispatch(TicketWorkspacesActions.getCompletedWorkspaceBoards({orgId, workspaceId}))
  }
}

