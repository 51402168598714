import { Ticket, TicketWorkspace, TicketWorkspaceBoard, TicketWorkspaceBoardUpdateParams, TicketWorkspaceTicketUpdateParams, TicketWorkspaceUpdateParams } from '../../../../../data-models/src/lib/tickets/tickets.interface';
import { createAction, props } from '@ngrx/store';

export const loadTicketWorkspace = createAction(
  '[Ticket Workspaces/API] Load Ticket Workspace',
  props<{userId: string, orgId: string, workspaceId: string}>()
);

export const loadTicketWorkspaceSuccess = createAction(
  '[Ticket Workspaces/API] Load Ticket Workspace Success',
  props<{ userId: string, ticketWorkspace: TicketWorkspace }>()
);

export const loadTicketWorkspaceFailure = createAction(
  '[Ticket Workspaces/API] Load Ticket Workspace Failure',
  props<{ error: any }>()
);

export const loadTicketWorkspaces = createAction(
  '[Ticket Workspaces/API] Load Ticket Workspaces',
  props<{orgId: string}>()
);

export const loadTicketWorkspacesSuccess = createAction(
  '[Ticket Workspaces/API] Load Ticket Workspaces Success',
  props<{ ticketWorkspaces: TicketWorkspace[] }>()
);

export const loadTicketWorkspacesFailure = createAction(
  '[Ticket Workspaces/API] Load Ticket Workspaces Failure',
  props<{ error: any }>()
);

export const createTicketWorkspace = createAction(
  '[Tickets/API] Create Tickets Workspace',
  props<{ orgId: string, workspaceName: string, jiraProject: string, sprintPage: boolean, description?: string }>()
)
export const createTicketWorkspaceSuccess = createAction(
  '[Tickets/API] Create Tickets Workspace Success',
  props<{ ticketWorkspace: TicketWorkspace, sprintPage: boolean }>()
)
export const createTicketWorkspaceFailure = createAction(
  '[Tickets/API] Create Tickets Workspace Failure',
  props<{ error: Error }>()
)
export const deleteTicketWorkspace = createAction(
  '[Tickets/API] Delete Ticket Workspace',
  props<{ orgId: string, workspaceId: string }>()
)
export const deleteTicketWorkspaceSuccess = createAction(
  '[Tickets/API] Delete Ticket Workspace Success',
  props<{ ticketWorkspace: TicketWorkspace }>()
)
export const deleteTicketWorkspaceFailure = createAction(
  '[Tickets/API] Delete Ticket Workspace Failure',
  props<{ error: Error }>()
)

export const createTicketWorkspaceBoard = createAction(
  '[Tickets/API] Create Tickets Workspace Board',
  props<{ orgId: string, workspaceId: string, boardName: string, startDate?: string, endDate?: string, description?: string }>()
)

export const createTicketWorkspaceBoardSuccess = createAction(
  '[Tickets/API] Create Tickets Workspace Board Success',
  props<{ ticketWorkspaceBoard: TicketWorkspaceBoard }>()
)

export const createTicketWorkspaceBoardFailure = createAction(
  '[Tickets/API] Create Tickets Workspace Board Failure',
  props<{ error: Error }>()
)

export const deleteTicketWorkspaceBoard = createAction(
  '[Tickets/API] Delete Tickets Workspace Board',
  props<{ orgId: string, workspaceId: string, boardId: number, newBoardId?: number }>()
)

export const deleteTicketWorkspaceBoardSuccess = createAction(
  '[Tickets/API] Delete Tickets Workspace Board Success',
  props<{ ticketWorkspaceBoard: TicketWorkspaceBoard }>()
)

export const deleteTicketWorkspaceBoardFailure = createAction(
  '[Tickets/API] Delete Tickets Workspace Board Failure',
  props<{ error: Error }>()
)

export const loadTicketWorkspaceSprintBoards = createAction(
  '[Ticket Workspaces/API] Load Ticket Workspace Sprint Boards',
  props<{ orgId: string, workspaceId: string, includeBacklog?: boolean }>()
)

export const loadTicketWorkspaceSprintBoardsSuccess = createAction(
  '[Ticket Workspaces/API] Load Ticket Workspace Sprint Boards Success',
  props<{ orgId: string, workspaceId: string, boards: TicketWorkspaceBoard[], tickets: Ticket[] }>()
)

export const loadTicketWorkspaceSprintBoardsFailure = createAction(
  '[Ticket Workspaces/API] Load Ticket Workspace Sprint Boards Failure',
  props<{ error: any }>()
)

export const updateTicketWorkspaceBoards = createAction(
  '[Ticket Workspaces/API] Update Ticket Workspace Boards',
  props<{ ticket: Ticket, ticketUpdateParams: TicketWorkspaceTicketUpdateParams }>()
)

export const updateTicketWorkspaceBoard = createAction(
  '[Ticket Workspaces/API] Update Ticket Workspace Board',
  props<{ orgId: string, workspaceId: string, boardId: number, boardUpdateParams: TicketWorkspaceBoardUpdateParams, userId?: string }>()
)

export const updateTicketWorkspaceBoardSuccess = createAction(
  '[Ticket Workspaces/API] Update Ticket Workspace Board Success',
  props<{ ticketWorkspaceBoard: TicketWorkspaceBoard, completeSprint: boolean, userId?: string }>()
)

export const updateTicketWorkspaceBoardFailure = createAction(
  '[Ticket Workspaces/API] Update Ticket Workspace Board Failure',
  props<{ error: any }>()
)

export const setActiveTicketWorkspaceBoard = createAction(
  '[Ticket Workspaces/API] Set Active Ticket Workspace Board',
  props<{ boardId: number | undefined}>()
)

export const updateTicketWorkspace = createAction(
  '[Ticket Workspaces/API] Update Ticket Workspace',
  props<{ orgId: string, workspaceId: string, ticketWorkspaceUpdateParams: TicketWorkspaceUpdateParams }>()
);

export const updateTicketWorkspaceSuccess = createAction(
  '[Ticket Workspaces/API] Update Ticket Workspace Success',
  props<{ ticketWorkspace: TicketWorkspace }>()
);

export const updateTicketWorkspaceFailure = createAction(
  '[Ticket Workspaces/API] Update Ticket Workspace Failure',
  props<{ error: any }>()
);

export const startSprint = createAction(
  '[Ticket Workspaces/API] Start Sprint', 
  props<{orgId: string, workspaceId: string, boardId: number}>()
);
export const startSprintFailure = createAction(
  '[Ticket Workspaces/API] Start Sprint Failure',
  props<{ error: any }>()
)

export const noAction = createAction(
  '[Ticket Workspaces/API] No Action'
)
export const getTicketWorkspaceBoard = createAction(
  '[Ticket Workspaces/API] Get Ticket Workspace Board',
  props<{ orgId: string, workspaceId: string, boardId: number }>()
);

export const getTicketWorkspaceBoardSuccess = createAction(
  '[Ticket Workspaces/API] Get Ticket Workspace Board Success',
  props<{ ticketWorkspaceBoard: TicketWorkspaceBoard }>()
);

export const getTicketWorkspaceBoardFailure = createAction(
  '[Ticket Workspaces/API] Get Ticket Workspace Board Failure',
  props<{ error: any }>()
);

export const addUserToTicketWorkspace = createAction(
  '[Ticket Workspaces/API] Add User To Ticket Workspace',
  props<{ orgId: string, workspaceId: string, newUserId: string }>()
);

export const addUserToTicketWorkspaceSuccess = createAction(
  '[Ticket Workspaces/API] Add User To Ticket Workspace Success',
  props<{ ticketWorkspace: TicketWorkspace }>()
);

export const addUserToTicketWorkspaceFailure = createAction(
  '[Ticket Workspaces/API] Add User To Ticket Workspace Failure',
  props<{ error: any }>()
);

export const removeUserFromTicketWorkspace = createAction(
  '[Ticket Workspaces/API] Remove User From Ticket Workspace',
  props<{ orgId: string, workspaceId: string, userId: string }>()
);

export const removeUserFromTicketWorkspaceSuccess = createAction(
  '[Ticket Workspaces/API] Remove User From Ticket Workspace Success',
  props<{ ticketWorkspace: TicketWorkspace }>()
);

export const removeUserFromTicketWorkspaceFailure = createAction(
  '[Ticket Workspaces/API] Remove User From Ticket Workspace Failure',
  props<{ error: any }>()
);

export const updateSlackChannel = createAction(
  '[Ticket Workspaces/API] Update SlackChannel',
  props<{ticketWorkspace: TicketWorkspace, slackChannelId: string, slackChannelName: string}>()
)

export const getCompletedWorkspaceBoards = createAction(
  '[Tickets/API] Get Completed Workspace Boards',
  props<{orgId: string, workspaceId: string}>()
);

export const getCompletedWorkspaceBoardsSuccess = createAction(
  '[Tickets/API] Get Completed Workspace Boards Success',
  props<{ ticketWorkspaceBoards: TicketWorkspaceBoard[] }>()
);

export const getCompletedWorkspaceBoardsFailure = createAction(
  '[Tickets/API] Get Completed Workspace Boards Failure',
  props<{ error: any }>() 
);
