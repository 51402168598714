import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'razroo-zeta-generic-delete-dialog',
    templateUrl: './generic-delete-dialog.component.html',
    styleUrls: ['./generic-delete-dialog.component.scss'],
    standalone: false
})
export class GenericDeleteDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}
}
