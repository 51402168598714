import { Pipe, PipeTransform } from '@angular/core';
import { WifejakChat } from '@razroo-zeta/data-models';

@Pipe({
  name: 'wifejakSelector',
  standalone: true
})
export class WifejakSelectorPipe implements PipeTransform {
  transform(wifejakChat: WifejakChat | undefined): string | undefined {
    if (!wifejakChat?.stepsWithSelectors?.length) {
      return undefined;
    }
    const selector = wifejakChat.stepsWithSelectors.find(step => step.selector)?.selector;
    console.log('WifejakSelectorPipe: ', selector);
    return selector;
  }
}
