import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as PathActions from './path.actions';
import { PathEntity } from './path.models';
import { TemplatePermission } from '@razroo-zeta/data-models';
import * as OrganizationsActions from '@razroo-zeta/data-access/organizations';
import * as GenerateCodeActions from '@razroo-zeta/data-access/organizations';

export const PATH_FEATURE_KEY = 'path';

export interface PathsState extends EntityState<PathEntity> {
  selectedId?: string | number; // which Path record has been selected
  loaded: boolean; // has the Path list been loaded
  error?: string | null; // last known error (if any)
}
export interface SecondaryPathsState extends EntityState<PathEntity> {
  selectedId?: string | number; // which Path record has been selected
  loaded: boolean; // has the Path list been loaded
  error?: string | null; // last known error (if any)
}
export interface ProjectPathsState extends EntityState<PathEntity> {
  selectedId?: string | number; // which Path record has been selected
  loaded: boolean; // has the Path list been loaded
  error?: string | null; // last known error (if any)
}
export interface PathVersionsState extends EntityState<PathEntity> {
  selectedId?: string | number; // which Path record has been selected
  loaded: boolean; // has the Path list been loaded
  error?: string | null; // last known error (if any)
}

export interface State {
  paths: PathsState;
  secondaryPaths: SecondaryPathsState;
  projectPaths: ProjectPathsState;
  pathVersions: PathVersionsState;
  pathPermissions: TemplatePermission[];
}


export interface PathPartialState {
  readonly [PATH_FEATURE_KEY]: State;
}

export const pathsAdapter: EntityAdapter<PathEntity> =
  createEntityAdapter<PathEntity>();

export const secondaryPathsAdapter: EntityAdapter<PathEntity> =
  createEntityAdapter<PathEntity>();
export const projectPathsAdapter: EntityAdapter<PathEntity> =
  createEntityAdapter<PathEntity>();
export const pathVersionsAdapter: EntityAdapter<PathEntity> =
  createEntityAdapter<PathEntity>();

export const pathsInitialState: PathsState = pathsAdapter.getInitialState({
  loaded: false
})
export const secondaryPathsInitialState: PathsState = pathsAdapter.getInitialState({
  loaded: false
})
export const projectPathsInitialState: PathsState = pathsAdapter.getInitialState({
  loaded: false
})
export const pathVersionsInitialState: PathsState = pathsAdapter.getInitialState({
  loaded: false
})

export const initialState: State = pathsAdapter.getInitialState({
  // set initial required properties
  paths: pathsInitialState,
  secondaryPaths: secondaryPathsInitialState,
  projectPaths: projectPathsInitialState,
  pathVersions: pathVersionsInitialState,
  pathPermissions: [],
  loaded: false,
});

const pathReducer = createReducer(
  initialState,
  on(PathActions.loadAllPaths, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(OrganizationsActions.updateOrganizationUser, (state, {activeProject}) => ({
      ...state,
      paths: {
        ...state.paths,
        selectedId: activeProject?.id ? activeProject?.id : state?.paths?.selectedId
      }
    }),
  ),
  on(PathActions.loadAllPathsSuccess,
    (state, { paths }) => ({
      ...state,
      paths: pathsAdapter.setAll(paths, { ...state.paths, loaded: true })
    })
  ),
  on(PathActions.loadProjectPathsSuccess,
    (state, { paths }) => ({
      ...state,
      projectPaths: projectPathsAdapter.setAll(paths, { ...state.paths, loaded: true })
    })
  ),
  on(PathActions.loadPathSuccess,
    (state, { path }) => ({
      ...state,
      paths: pathsAdapter.upsertOne(path, {
        ...state.paths, loaded: true,
        selectedId: path.id
      })
    })
  ),
  on(GenerateCodeActions.updateActiveProject,
    (state, { activeProject }) => ({
      ...state,
      paths: pathsAdapter.upsertOne(activeProject as any, {
        ...state.paths, loaded: true,
        selectedId: activeProject.id
      })
    })
  ),
  on(PathActions.loadSecondaryPathSuccess,
    (state, { path, parentPath }) => ({
      ...state,
      paths: pathsAdapter.upsertOne({...path, parentPath: parentPath}, {
        ...state.paths, loaded: true,
        selectedId: path.id
      })
    })
  ),
  on(PathActions.loadPathFailure, (state, { error }) => ({ ...state, error })),

  on(PathActions.createPathSuccess,
    (state, { path }) => ({
      ...state,
      paths: pathsAdapter.addOne(path, {
        ...state.paths,
        loaded: true,
        selectedId: path.id
      })
    })
  ),
  on(PathActions.createProjectSuccess,
    (state, { project }) => ({
      ...state,
      projectPaths: projectPathsAdapter.addOne(project, {
        ...state.projectPaths,
        loaded: true,
        selectedId: project.id
      })
    })
  ),
  on(PathActions.searchPathSuccess,
    (state, { path }) => ({
      ...state,
      paths: pathsAdapter.updateOne({id: path.id, changes: {stepper: path.stepper}}, {
         ...state.paths,
         loaded: true,
         selectedId: path.id
      })
    })
  ),
  on(PathActions.archiveRecipeSuccess, (state, { recipe, path }) => ({
    ...state,
    paths: pathsAdapter.updateOne({id: recipe.pathId!, changes: {stepper: [...path.stepper?.filter(rec => rec.id != recipe.id)!], count: (path.count! - 1)}}, {
      ...state.paths,
      loaded: true
    })
  })),
  on(PathActions.deleteRecipeSuccess, (state, { recipe, path }) => ({
    ...state,
    paths: pathsAdapter.updateOne({id: recipe.pathId!, changes: {stepper: [...path.stepper?.filter(rec => rec.id != recipe.id)!], count: (path.count! - 1)}}, {
      ...state.paths,
      loaded: true
    })
  })),
  on(PathActions.archivePathSuccess, (state, { path }) => ({
    ...state,
    paths: pathsAdapter.removeOne(path.id, { ...state.paths, loaded: true })
  })),
  on(PathActions.loadPathPermissionsSuccess, (state, { pathPermissions }) => ({
    ...state,
    pathPermissions: pathPermissions
  })),
  on(PathActions.getSecondaryPathsSuccess,
    (state, { paths }) => ({
      ...state,
      secondaryPaths: secondaryPathsAdapter.setAll(paths, {
        ...state.secondaryPaths,
        loaded: true
      })
    })
  ),
  on(
    PathActions.addSecondaryPathSuccess,
    PathActions.removeSecondaryPathSuccess,
    (state, { secondaryPaths }) => ({
      ...state,
      secondaryPaths:  secondaryPathsAdapter.setAll(secondaryPaths, {
        ...state.secondaryPaths,
        loaded: true
      })
    })
  ),
  on(PathActions.updatePathSuccess, (state, { path }) => ({
    ...state,
    paths: pathsAdapter.updateOne({id: path.id, changes: {description: path.description, title: path.title, privacy: path.privacy, marketplace: path.marketplace, beta: path.beta, paid: path.paid}}, {
      ...state.paths,
      loaded: true
    })
  })),
  on(PathActions.toggleRecipeAsBatch, (state, { recipe }) => {
    const updatedStepper = (state.paths.entities[recipe.pathId]?.stepper as any).map(step => {
      if(step.id === recipe.id) {
        const updatedStep = {
          ...step,
          batchId: step.batchId,
          executionId: step.executionId
        }
        return updatedStep;
      } else {
        return step;
      }
    });

    const updatedPaths = pathsAdapter.updateOne({id: recipe.pathId, changes: {
      stepper: updatedStepper
    }}, {
      ...state.paths
    });

    return {
      ...state,
      paths: updatedPaths
    }
  }),
  on(PathActions.getPathVersionsSuccess,
    (state, { paths }) => ({
      ...state,
      pathVersions: pathVersionsAdapter.setAll(paths, { ...state.pathVersions, loaded: true })
    })
  ),

  on(PathActions.addMarketplacePathToCommunitySuccess, 
    (state, { path }) => ({
      ...state,
      paths: pathsAdapter.updateOne({id: path.id, changes: {marketplacePaths: path.marketplacePaths}}, {
         ...state.paths,
         loaded: true,
         selectedId: path.id
      })
    })
  ),
  on(PathActions.removeMarketplacePathFromCommunityPathSuccess, 
    (state, { path }) => ({
      ...state,
      paths: pathsAdapter.updateOne({id: path.id, changes: {marketplacePaths: path.marketplacePaths}}, {
         ...state.paths,
         loaded: true,
         selectedId: path.id
      })
    })
  ),
  on(PathActions.reorderCommunityMarketplacePathsSuccess, 
    (state, { path }) => ({
      ...state,
      paths: pathsAdapter.updateOne({id: path.id, changes: {marketplacePaths: path.marketplacePaths}}, {
         ...state.paths,
         loaded: true,
         selectedId: path.id
      })
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return pathReducer(state, action);
}
